import { OrderStatus } from '@/compiled_proto/com/celertech/orderrouting/api/enums/OrderStatusProto';
import VirtualizedTable from '@/components/table/VirtualizedTable';
import { defaultColumn, qtyFormatterConfig } from '@/config/config';
import { getTradeType } from '@/helpers/blotterHelper';
import { tradeColumns } from '@/helpers/table/tradeBlotterTableHelper';
import { BlotterTrade } from '@/model/blotters';
import { useAppSelector } from '@/state/hooks';
import { selectCredentials, selectCurrentAccount } from '@/state/reducers/authSlice';
import { selectBlotterTrades } from '@/state/reducers/blotterSlice';
import { selectGeneralSettings } from '@/state/reducers/settingSlice';
import { formatDateTime, formatLocalDateTime } from '@/utils/format';
import { getInstrumentFormatter } from '@/utils/hooks/useInstrument';
import { useTrueDateFormatter } from '@/utils/hooks/useTrueDateFormatter';
import { getAssetName, translatePair } from '@/utils/symbolMapping';
import {
    SortingState,
    getCoreRowModel,
    getFacetedMinMaxValues,
    getFacetedRowModel,
    getFacetedUniqueValues,
    getFilteredRowModel,
    getSortedRowModel,
    useReactTable
} from '@tanstack/react-table';
import { useEffect, useMemo, useState } from 'react';
import { useLocale, useNumberFormatter } from 'react-aria';

export interface TradeRow extends BlotterTrade {
    instrument: string;
    type: string;
    netQtyPosition: number;
    openPrice: number;
    currentPrice: number;
    unrealisedPnl: number;
    settlementDate: string;
    accountCode: string;
}

const TradeBlotterTable = (props: any) => {
    const { setCSVData } = props;

    const trades = useAppSelector(selectBlotterTrades);
    const credentials = useAppSelector(selectCredentials);
    const generalSettings = useAppSelector(selectGeneralSettings);
    const currentAccount = useAppSelector(selectCurrentAccount);

    const { locale } = useLocale();
    const formatDate = useTrueDateFormatter();
    const formatQty = useNumberFormatter(qtyFormatterConfig);

    const [sorting, setSorting] = useState<SortingState>([
        { id: 'executionTime', desc: true },
        { id: 'fillNo', desc: true }
    ]);

    const columns = useMemo(() => tradeColumns(formatQty, generalSettings), [formatQty, generalSettings]);
    const isUTC = useMemo(() => generalSettings.timezone.value === 'UTC', [generalSettings]);
    const formattedTrades: any = useMemo(() => {
        return trades
            .filter((trade) => trade.account === currentAccount || trade.account === currentAccount?.slice(0, 36))
            .map((trade) => mapTradeBlotters(trade, locale, credentials, formatDate, isUTC));
    }, [trades, locale, credentials, currentAccount, isUTC]);

    const tableInstance = useReactTable({
        data: formattedTrades,
        columns,
        state: { sorting },
        defaultColumn,
        enableColumnResizing: true,
        columnResizeMode: 'onChange',
        globalFilterFn: 'includesString',
        onSortingChange: (sorting) => setSorting(sorting),
        getCoreRowModel: getCoreRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        getSortedRowModel: getSortedRowModel(),
        getFacetedRowModel: getFacetedRowModel(),
        getFacetedUniqueValues: getFacetedUniqueValues(),
        getFacetedMinMaxValues: getFacetedMinMaxValues(),
        getSubRows: (row: any) => row.subRows,
        enableRowSelection: true
    });

    useEffect(() => {
        setCSVData(formattedTrades);
    }, [formattedTrades]);

    return <VirtualizedTable tableInstance={tableInstance} isLoadingTableContent={false} />;
};

export default TradeBlotterTable;

export const mapTradeBlotters = (trade, locale, credentials, formatDate, isUTC) => {
    const { formatPrice, formatAvgPrice } = getInstrumentFormatter(locale, trade.pair);
    const currentAccountProperties =
        credentials?.accounts?.find((account) => account.code === trade.account)?.properties || [];
    const accountType = currentAccountProperties?.find((property) => property.key === 'TYPE')?.value as
        | 'NOP'
        | 'MARGIN';

    const executionTime =
        trade.executionTime &&
        (isUTC
            ? formatDateTime(formatDate, trade.executionTime)
            : formatLocalDateTime(formatDate, trade.executionTime));

    let tableSide = 'BUY';
    let tableFillNo = '-';

    if (trade.qty1 !== 0) {
        if (trade.qty1 > 0) tableSide = 'BUY';
        else tableSide = 'SELL';
    } else tableSide = trade.side;

    if ([OrderStatus.FILLED, OrderStatus.PART_FILLED].includes(OrderStatus[trade.status as string]))
        tableFillNo = trade.fillNo;
    else tableFillNo = '-';

    return {
        ...trade,
        time: executionTime,
        executionTime,
        fillNo: tableFillNo,
        fillPrice: formatPrice(trade.fillPrice),
        avgFillPrice: formatAvgPrice(trade.avgFillPrice),
        type: getTradeType(trade.pair, accountType),
        instrument: translatePair(trade.pair),
        side: tableSide,
        ccy1: getAssetName(trade.ccy1),
        ccy2: getAssetName(trade.ccy2)
    };
};
