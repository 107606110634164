import { FormInputWrapper } from '@/components/inputs/FormInputWrapper';
import InputController from '@/components/inputs/InputController';
import Select from '@/components/inputs/Select';
import { Tab } from '@headlessui/react';
import { useDisclosure } from '../../../utils/hooks/useDisclosure';
import ResetPasswordModal from '../ResetPasswordModal';

export const idleTimeoutOptions = [
    { label: '10 Minutes', value: 600000 },
    { label: '20 Minutes', value: 1200000 },
    { label: '30 Minutes', value: 1800000 },
    { label: '60 Minutes', value: 3600000 },
    { label: '180 Minutes', value: 10800000 },
    { label: '360 Minutes', value: 21600000 }
];

const AdvancedTab = () => {
    const [opened, handlers] = useDisclosure(false);

    return (
        <Tab.Panel className="p-2 py-3 sm:p-4 h-auto lg:h-[400px]">
            <div className="space-y-4">
                <div className="space-y-3 sm:space-y-2">
                    <InputController name="general.idleTimeout" label="Idle Timeout Settings" required>
                        <Select options={idleTimeoutOptions} />
                    </InputController>
                    <div className="space-y-3 sm:space-y-1">
                        <div className="text-xs text-neutral-400">
                            Set an idle timeout for the trading interface, this can be changed at any time
                        </div>
                        <div className="text-xs text-neutral-400">
                            This will affect the duration of idle time before the interface prompts you to log out.
                        </div>
                    </div>
                </div>
                <div className="flex flex-col sm:flex-row justify-between items-center gap-0 sm:gap-3 text-neutral-200 w-full">
                    <FormInputWrapper label="Change Password">
                        <button
                            type="button"
                            className="w-full text-sm p-2 rounded-md bg-brand-background-dark border border-neutral-700 hover:bg-brand-primary"
                            onClick={handlers.open}>
                            Reset Current Password
                        </button>
                    </FormInputWrapper>
                </div>
            </div>
            <ResetPasswordModal opened={opened} handlers={handlers} />
        </Tab.Panel>
    );
};

export default AdvancedTab;
