import { Table as TableType, flexRender } from '@tanstack/react-table';

import { useVirtualizer } from '@tanstack/react-virtual';
import cn from 'classnames';
import { useMemo, useRef } from 'react';
import { MdArrowDropDown, MdArrowDropUp } from 'react-icons/md';
import Loader from '../common/Loader';

export type VirtualizedTableProps<T extends object> = {
    tableInstance: TableType<T>;
    isLoadingTableContent: boolean;
    tdClassNames?: string;
    rowVirtualizer?: any;
    onRowClick?: (row: any) => void;
    onCellContextMenu?: (row: any) => void;
    onRowDoubleClicked?: (row: any) => void;
    onRowClicked?: (row: any) => void;
};

const VirtualizedTable = <T extends object>(props: VirtualizedTableProps<T>) => {
    const { tableInstance, isLoadingTableContent } = props;

    const { rows } = tableInstance.getRowModel();
    const hasRecords = useMemo(() => rows.length > 0, [rows]);
    const tableContainerRef = useRef<HTMLDivElement>(null);

    const rowVirtualizer = useVirtualizer({
        count: rows.length,
        estimateSize: () => 29, //estimate row height for accurate scrollbar dragging
        getScrollElement: () => tableContainerRef.current,
        //measure dynamic row height, except in firefox because it measures table border height incorrectly
        measureElement:
            typeof window !== 'undefined' && navigator.userAgent.indexOf('Firefox') === -1
                ? (element) => element?.getBoundingClientRect().height
                : undefined,
        overscan: 5
    });

    return (
        <div className={cn('h-full border border-neutral-600')}>
            {isLoadingTableContent ? (
                <div className="flex justify-center p-6">
                    <Loader className="h-6 w-6" />
                </div>
            ) : (
                <div
                    className={cn('flex flex-col h-full overflow-auto')}
                    ref={tableContainerRef}
                    style={{
                        overflow: 'auto', //our scrollable table container
                        position: 'relative' //needed for sticky header
                    }}>
                    <table className="grid">
                        <TableHeaders {...props} />
                        {hasRecords && <TableBody rowVirtualizer={rowVirtualizer} {...props} />}
                        <TableFooter {...props} />
                    </table>

                    {!hasRecords && (
                        <div className="px-2 py-1.5 flex flex-1 justify-center items-center text-neutral-200 text-sm">
                            No Rows To Show
                        </div>
                    )}
                </div>
            )}
        </div>
    );
};

export default VirtualizedTable;

const TableHeaders = (props: VirtualizedTableProps<any>) => {
    const { tableInstance } = props;
    return (
        <thead className="grid sticky top-0 z-[1] bg-brand-background-dark border-b border-neutral-600">
            {tableInstance.getHeaderGroups().map((headerGroup) => (
                <tr key={headerGroup.id} className="flex w-full">
                    {headerGroup.headers.map((header, index) => {
                        const isSorted = header.column.getIsSorted() as string;
                        return (
                            <th
                                key={header.id}
                                colSpan={header.colSpan}
                                className={cn('flex relative py-1.5 text-xs font-semibold text-white', {
                                    ['pl-2 pr-2']: index === 0,
                                    ['pr-2']: index !== 0 && index !== headerGroup.headers.length - 1,
                                    ['px-2']: index === headerGroup.headers.length - 1
                                })}
                                style={{ width: header.getSize() }}>
                                {!header.isPlaceholder && (
                                    <div className="flex flex-col w-[inherit] gap-1">
                                        <div
                                            className={cn('flex gap-1 items-center pr-3', {
                                                'cursor-pointer select-none': header.column.getCanSort()
                                            })}
                                            onClick={header.column.getToggleSortingHandler()}>
                                            <div className="truncate">
                                                {flexRender(header.column.columnDef.header, header.getContext())}
                                            </div>
                                            {isSorted && (
                                                <div className="h-4 w-4">
                                                    {isSorted === 'asc' && (
                                                        <MdArrowDropUp className="h-4 w-4 text-primary" />
                                                    )}
                                                    {isSorted === 'desc' && (
                                                        <MdArrowDropDown className="h-4 w-4 text-primary" />
                                                    )}
                                                </div>
                                            )}
                                        </div>
                                        <div
                                            onMouseDown={header.getResizeHandler()}
                                            onTouchStart={header.getResizeHandler()}
                                            className={cn('table-resizer', {
                                                isResizing: header.column.getIsResizing()
                                            })}
                                        />
                                    </div>
                                )}
                            </th>
                        );
                    })}
                </tr>
            ))}
        </thead>
    );
};

const TableBody = (props: VirtualizedTableProps<any>) => {
    const { tableInstance, tdClassNames, rowVirtualizer, onCellContextMenu, onRowDoubleClicked, onRowClicked } = props;
    const { rows } = tableInstance.getRowModel();

    return (
        <tbody
            className="grid relative w-full border-b border-neutral-700"
            style={{
                height: `${rowVirtualizer.getTotalSize()}px`
            }}>
            {rowVirtualizer.getVirtualItems().map((virtualRow, index) => {
                const row = rows[virtualRow.index];

                return (
                    <tr
                        key={row.id}
                        data-index={index}
                        ref={(node) => rowVirtualizer.measureElement(node)}
                        onClick={(e) => {
                            row.getToggleExpandedHandler()();
                            onRowClicked?.({ ...row, event: e });
                        }}
                        onDoubleClick={(e) => onRowDoubleClicked?.({ ...row, event: e })}
                        onContextMenu={(e) => onCellContextMenu?.({ ...row, event: e })}
                        className={cn('border-neutral-700 hover:bg-neutral-700 cursor-pointer', {
                            'bg-brand-background-dark': index % 2 !== 0
                        })}
                        style={{
                            display: 'flex',
                            position: 'absolute',
                            transform: `translateY(${virtualRow.start}px)`, //this should always be a `style` as it changes on scroll
                            width: '100%'
                        }}>
                        {row.getVisibleCells().map((cell, index) => (
                            <td
                                key={cell.id}
                                className={cn(
                                    'py-1 border-t border-neutral-700 text-xs font-normal lg:text-sm text-neutral-200',
                                    {
                                        ['pl-2']: index === 0,
                                        ['pr-2']: index !== 0 && index !== row.getVisibleCells().length - 1,
                                        ['px-2']: index === row.getVisibleCells().length - 1
                                    },
                                    tdClassNames
                                )}
                                style={{ width: cell.column.getSize() }}>
                                <div className="truncate">
                                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                </div>
                            </td>
                        ))}
                    </tr>
                );
            })}
        </tbody>
    );
};

const TableFooter = (props: VirtualizedTableProps<any>) => {
    const { tableInstance } = props;
    return (
        <tfoot>
            {tableInstance.getFooterGroups().map((footerGroup) => {
                return (
                    <tr key={footerGroup.id} className="bg-backgroundMedium">
                        {footerGroup.headers.map((header) => {
                            const footerHeader = flexRender(header.column.columnDef.footer, header.getContext());
                            return footerHeader ? (
                                <th
                                    key={header.id}
                                    colSpan={header.colSpan}
                                    className={`px-2 py-1.5 text-sm font-normal lg:text-base`}>
                                    {header.isPlaceholder ? null : footerHeader}
                                </th>
                            ) : null;
                        })}
                    </tr>
                );
            })}
        </tfoot>
    );
};
