import { Side } from '@/compiled_proto/com/celertech/positionmanager/api/enums/SideProto';
import { TimeInForceType } from '@/compiled_proto/com/celertech/positionmanager/api/enums/TimeInForceTypeProto';
import Divider from '@/components/common/Divider';
import FormBody from '@/components/form/components/FormBody';
import FormCurrency from '@/components/form/components/FormCurrency';
import FormFooter from '@/components/form/components/FormFooter';
import FormOrderTypeSwitch from '@/components/form/components/FormOrderTypeSwitch';
import FormSubmit from '@/components/form/components/FormSubmit';
import Totals from '@/components/form/components/Totals';
import {
    MarketOrderFormInput,
    MarketOrderFormValues,
    marketOrderSchema
} from '@/components/form/schema/marketOrderSchema';
import ExecutionInputController from '@/components/inputs/ExecutionInputController';
import RHFNumberInput from '@/components/inputs/RHFNumberInput';
import Select from '@/components/inputs/Select';
import { OpenPositionRow } from '@/components/trader-modules/Blotters/OpenPositionTable';
import { submitMarketOrder } from '@/services/OrderService';
import { useAppSelector } from '@/state/hooks';
import { selectCredentials, selectCurrentAccount } from '@/state/reducers/authSlice';
import { selectSettings } from '@/state/reducers/settingSlice';
import { useDidUpdate } from '@/utils/hooks/useDidUpdate';
import { UseOrderExecutionTraderReturn } from '@/utils/hooks/useOrderExecutionTrader';
import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

const durationOptions: { label: keyof typeof TimeInForceType; value: TimeInForceType }[] = [
    { label: 'IOC', value: TimeInForceType.IOC },
    { label: 'FOK', value: TimeInForceType.FOK }
];

const defaultValues: Partial<MarketOrderFormInput> = {
    quantity: null,
    duration: durationOptions[0]
};

interface MarketOrderFormProps extends UseOrderExecutionTraderReturn {
    prefillValues?: OpenPositionRow;
    onClose?: () => void;
    closeOnSubmit?: boolean;
}

function MarketOrderForm(props: MarketOrderFormProps) {
    const { prefillValues, onClose, closeOnSubmit = false, ...orderExecutionProps } = props;
    const {
        ccy1,
        ccy2,
        sideState,
        currencyState,
        orderBook,
        activeCurrencyConfig,
        activeTicker,
        ccy1Config,
        isCcy2Order
    } = orderExecutionProps;

    const settings = useAppSelector(selectSettings);
    const credentials = useAppSelector(selectCredentials);
    const currentAccount = useAppSelector(selectCurrentAccount);

    const [side, setSide] = sideState;
    const [currency, setCurrency] = currencyState;

    const { asks, bids, bestPrice } = orderBook;
    const { min_order_size, order_decimals, increments } = activeCurrencyConfig;

    const form = useForm<MarketOrderFormInput>({
        defaultValues,
        mode: 'onChange',
        resolver: yupResolver(
            marketOrderSchema({
                min_order_size,
                order_decimals,
                currencyOut: currency,
                currencyPair: activeTicker.show
            })
        )
    });

    const {
        reset,
        register,
        handleSubmit,
        formState: { isSubmitting, isValid }
    } = form;

    const onSubmit = async (data: MarketOrderFormInput, e) => {
        const dataValidated = data as MarketOrderFormValues;
        if (dataValidated.quantity && credentials) {
            if (closeOnSubmit) onClose?.();
            await submitMarketOrder(
                {
                    spotPrice: bestPrice,
                    securityId: activeTicker.celer,
                    currencyOut: currency,
                    quantity: dataValidated.quantity,
                    timeInForce: dataValidated.duration.value as TimeInForceType,
                    side
                },
                credentials,
                currentAccount
            );
        }
    };

    const onError = (errors) => console.error(errors);

    useDidUpdate(() => reset(defaultValues), [currency]);

    useEffect(() => {
        if (prefillValues) {
            setSide((prefillValues.netQtyPosition || 0) > 0 ? Side.SELL : Side.BUY);
            reset({
                ...defaultValues,
                duration: settings.trading.defaultMarketOrderDuration,
                quantity: +Math.abs(prefillValues.netQtyPosition).toFixed(ccy1Config.order_decimals)
            });
        } else reset({ ...defaultValues, duration: settings.trading.defaultMarketOrderDuration });
    }, [activeTicker.celer]);

    return (
        <FormProvider {...form}>
            <form
                onSubmit={handleSubmit(onSubmit, onError)}
                className="relative flex flex-col h-full w-full text-neutral-200">
                <FormBody side={side} mode="Modal">
                    <FormOrderTypeSwitch initialValue={side} onChange={setSide} />
                    <FormCurrency {...props} mode="Trader" currency={currency} setCurrency={setCurrency} />

                    <ExecutionInputController name="duration" label="Duration *">
                        <Select type="execution" options={durationOptions} />
                    </ExecutionInputController>
                    <RHFNumberInput
                        {...register('quantity')}
                        label="Quantity *"
                        placeholder="Quantity"
                        min={min_order_size}
                        step={increments}
                    />
                    <Totals
                        activePair={activeTicker}
                        side={side}
                        ccy1={ccy1}
                        ccy2={ccy2}
                        ccy2Order={isCcy2Order}
                        bids={bids}
                        asks={asks}
                        isTrader
                    />
                </FormBody>
                <Divider />
                <FormFooter mode="Modal">
                    <FormSubmit side={side} isValid={isValid} disabled={isSubmitting || !isValid} className="left-6" />
                </FormFooter>
            </form>
        </FormProvider>
    );
}

export default MarketOrderForm;
