import { UseDisclosureReturn, useDisclosure } from '@/utils/hooks/useDisclosure';
import cn from 'classnames';
import { Fragment, useMemo } from 'react';
import { IconType } from 'react-icons/lib';
import { MdCurrencyExchange, MdOutlineAccountBalance } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';
import WeAreMoving from '../modal/WithdrawalModal/WeAreMoving';

interface Action {
    label: string;
    Icon: IconType;
    onClick: any;
    show: boolean;
}

interface LiteActionsProps {
    orderModal: UseDisclosureReturn[1];
    setInitFrom(ccy: string): void;
    setInitTo(ccy: string): void;
}

const depositEnabled = window.config.modules.deposit.enabled;
const withdrawalEnabled = window.config.modules.withdrawal.enabled;

const LiteActions = ({ orderModal, setInitFrom, setInitTo }: LiteActionsProps) => {
    const navigate = useNavigate();
    const withdrawal = useDisclosure(false);

    const actions: Action[] = useMemo(
        () => [
            {
                label: 'Convert',
                Icon: MdCurrencyExchange,
                onClick: () => {
                    setInitFrom('');
                    setInitTo('');
                    orderModal.toggle();
                },
                show: true
            },
            // {
            //     label: 'Deposit',
            //     Icon: MdOutlineAccountBalanceWallet,
            //     onClick: () => navigate('/lite/transfer?option=deposit'),
            //     show: depositEnabled
            // },
            {
                label: 'Withdraw',
                Icon: MdOutlineAccountBalance,
                onClick: withdrawal[1].open,
                // onClick: () => navigate('/lite/transfer?option=withdrawal'),
                show: withdrawalEnabled
            }
        ],
        [orderModal]
    );

    return (
        <Fragment>
            <div className="flex flex-wrap gap-3 sm:gap-5 py-2 sm:py-4">
                {actions.map(({ label, Icon, onClick, show }, index) => (
                    <Fragment key={index}>
                        {show && (
                            <div className="flex flex-col group items-center gap-1 cursor-pointer" onClick={onClick}>
                                <div
                                    className={cn('flex items-center justify-center p-2 sm:p-3 rounded-full', {
                                        ['bg-brand-primary group-hover:bg-brand-primary-light']: label === 'Convert',
                                        ['bg-neutral-700 group-hover:bg-neutral-500']: label !== 'Convert'
                                    })}>
                                    <Icon className="h-3 w-3 sm:h-4 sm:w-4" />
                                </div>
                                <span className="text-xs sm:text-sm">{label}</span>
                            </div>
                        )}
                    </Fragment>
                ))}
            </div>
            {withdrawalEnabled && <WeAreMoving opened={withdrawal[0]} handlers={withdrawal[1]} />}
        </Fragment>
    );
};

export default LiteActions;
