import { OrderStatus } from '@/compiled_proto/com/celertech/orderrouting/api/enums/OrderStatusProto';
import { OrderBlotterRow } from '@/components/modules/Blotters/OrderBlotterTable';
import { CellContext, ColumnDef } from '@tanstack/react-table';
import cn from 'classnames';
import { MdChevronRight, MdLink } from 'react-icons/md';

// OrderBlotterRow | OrderHistory
export const orderStatusColorCell = ({ row }: CellContext<any, any>) => {
    const val = row.original.status as keyof typeof OrderStatus;

    if (val === 'NEW') return 'text-brand-primary';
    else if (['PART_FILLED', 'FILLED', 'TRADE'].includes(val)) return 'text-brand-primary';
    else if (val.includes('PENDING')) return 'text-neutral-200';
    else if (['CANCELED', 'REJECTED'].includes(val)) return 'text-brand-red';
    else return 'text-neutral-200';
};

export const orderColumns = (generalSettings): ColumnDef<OrderBlotterRow>[] => {
    return [
        {
            accessorKey: 'orderId',
            header: 'Order Id',
            size: 160,
            cell: ({ row, getValue }: CellContext<OrderBlotterRow, any>) => {
                const canExpand = row.getCanExpand();

                return (
                    <div
                        className="flex items-center gap-1"
                        style={{
                            paddingLeft: `${row.depth * 0}rem`
                        }}>
                        <div className={`flex space-x-1 truncate`}>
                            {canExpand && (
                                <button title={row.getIsExpanded() ? 'Collapse current row' : 'Expand current row'}>
                                    <MdChevronRight
                                        className={cn('h-3.5 w-3.5 text-primary duration-200', {
                                            ['rotate-90']: row.getIsExpanded()
                                        })}
                                        aria-hidden
                                    />
                                </button>
                            )}

                            {!canExpand && row.original.parentStrategyID && (
                                <MdLink className={cn('h-3.5 w-3.5 text-primary duration-200 rotate-90')} aria-hidden />
                            )}

                            <span className="truncate">{getValue()}</span>
                        </div>
                    </div>
                );
            }
        },
        {
            accessorKey: 'status',
            header: 'Status',
            size: 80,
            cell: (context: CellContext<OrderBlotterRow, any>) => {
                const { getValue } = context;
                return <span className={orderStatusColorCell(context)}>{getValue()}</span>;
            }
        },
        {
            accessorKey: 'strategy',
            header: 'Strategy',
            size: 80
        },
        { accessorKey: 'instrument', header: 'Instrument', size: 100 },
        { accessorKey: 'orderType', header: 'Order Type', size: 80 },
        {
            accessorKey: 'side',
            header: 'Side',
            size: 60,
            cell: ({ row, getValue }: CellContext<OrderBlotterRow, any>) => {
                const value = getValue();
                return (
                    <div
                        className={cn('', {
                            'text-brand-primary': value === 'BUY',
                            'text-brand-red': value === 'SELL'
                        })}>
                        {value}
                    </div>
                );
            }
        },
        { accessorKey: 'ccy', header: 'Ccy', size: 60 },
        { accessorKey: 'qty', header: 'Qty', size: 80 },
        { accessorKey: 'price', header: 'Price', size: 80 },
        { accessorKey: 'slippage', header: 'Slippage', size: 80 },
        { accessorKey: 'lastPrice', header: 'Last Price', size: 80 },
        { accessorKey: 'lastQty', header: 'Last Qty', size: 80 },
        { accessorKey: 'avgPrice', header: 'Avg Price', size: 80 },
        { accessorKey: 'stopPrice', header: 'Stop Price', size: 80 },
        { accessorKey: 'cumQty', header: 'Cum Qty', size: 80 },
        { accessorKey: 'leavesQty', header: 'Leaves Qty', size: 80 },
        { accessorKey: 'created', header: `Created (${generalSettings.timezone.value})`, size: 160 },
        { accessorKey: 'updated', header: `Updated (${generalSettings.timezone.value})`, size: 160 },
        { accessorKey: 'account', header: 'Account', size: 100 },
        { accessorKey: 'user', header: 'User', size: 150 },
        {
            accessorKey: 'executionType',
            header: 'Execution Type',
            size: 140,
            cell: (context: CellContext<OrderBlotterRow, any>) => {
                const { getValue } = context;
                return <span className={orderStatusColorCell(context)}>{getValue()}</span>;
            }
        },
        { accessorKey: 'tif', header: 'Tif', size: 60 }
    ];
};
