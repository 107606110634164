import { Side } from '@/compiled_proto/com/celertech/positionmanager/api/enums/SideProto';
import { useMediaQuery } from '@/utils/hooks/useMediaQuery';
import cn from 'classnames';
import { Fragment } from 'react';

interface FormBodyProps {
    children: React.ReactNode;
    side: Side;
    mode?: 'Module' | 'Modal';
}

const FormBody = (props: FormBodyProps) => {
    const { children, side, mode = 'Module' } = props;
    const largeHeight = useMediaQuery('(min-height: 900px)');

    return (
        <Fragment>
            {mode === 'Module' && (
                <div
                    className={cn(
                        'flex flex-col flex-1 overflow-y-auto gap-3 mb-14 p-2 pb-0',
                        { ['basis-0']: !largeHeight },
                        Side[side].toLowerCase()
                    )}>
                    {children}
                </div>
            )}
            {mode === 'Modal' && (
                <div
                    className={cn(
                        'flex flex-col flex-1 overflow-y-auto gap-3 p-2 sm:p-4 pb-14',
                        { ['basis-0']: !largeHeight },
                        Side[side].toLowerCase()
                    )}>
                    {children}
                </div>
            )}
        </Fragment>
    );
};

export default FormBody;
