import { Side } from '@/compiled_proto/com/celertech/marketdata/api/enums/SideProto';
import { calculateVWAP } from '@/helpers/orderbookHelper';
import { useAppSelector } from '@/state/hooks';
import { PairMap, selectActiveBidAsk } from '@/state/reducers/marketPairSlice';
import { useCurrency } from '@/utils/hooks/useCurrency';
import { useInstrument } from '@/utils/hooks/useInstrument';
import { PriceLevel } from '@/utils/pricebook';
import BigNumber from 'bignumber.js';
import { useMemo } from 'react';
import { useNumberFormatter } from 'react-aria';
import { useFormContext } from 'react-hook-form';

interface TotalsProps {
    name?: string;
    activePair: PairMap;
    side: Side;
    ccy1: string;
    ccy2: string;
    ccy2Order: boolean;
    bids: PriceLevel[] | null;
    asks: PriceLevel[] | null;
    isTrader?: boolean;
}

const Totals = ({
    name = 'quantity',
    activePair,
    side,
    ccy2Order,
    ccy1,
    ccy2,
    bids,
    asks,
    isTrader = false
}: TotalsProps) => {
    const bidAsk = useAppSelector(selectActiveBidAsk);
    const tickerBidAsk = useAppSelector((state) => state.marketPair.bidAsk[state.ticker.activeTicker?.celer || '']);

    const form = useFormContext();
    const { watch, getFieldState, formState } = form;

    const quantity = watch(name);
    const quantityState = getFieldState(name, formState);

    const { formatPrice } = useInstrument(activePair.celer);
    const { order_decimals } = useCurrency(ccy2Order ? ccy1 : ccy2);

    const totalFormatter = useNumberFormatter({
        minimumFractionDigits: order_decimals,
        maximumFractionDigits: order_decimals
    });

    const orderQuantity = useMemo(() => {
        if (ccy2Order) {
            if (side === Side.BUY && asks) {
                return BigNumber(+quantity)
                    .dividedBy(asks[asks.length - 1].price)
                    .toNumber();
            } else if (side === Side.SELL && bids) {
                return BigNumber(+quantity)
                    .dividedBy(bids[0].price)
                    .toNumber();
            }
            return 0;
        } else {
            return +quantity;
        }
    }, [quantity]);

    const matchType = useMemo(() => {
        if (ccy2Order) {
            return side === Side.BUY ? 'bid' : 'ask';
        } else {
            return side === Side.BUY ? 'ask' : 'bid';
        }
    }, [side, ccy2Order]);

    const { vwap, message } = useMemo(() => {
        if (orderQuantity && !quantityState.invalid) {
            if ((side === Side.BUY && !ccy2Order) || (side === Side.SELL && ccy2Order)) {
                // use asks
                const reversedAsks = asks ? [...asks].reverse() : [];
                return calculateVWAP(reversedAsks, orderQuantity);
            } else if ((side === Side.SELL && !ccy2Order) || (side === Side.BUY && ccy2Order)) {
                // use bids
                return calculateVWAP(bids, orderQuantity);
            } else {
                return { vwap: null, message: null };
            }
        } else {
            return { vwap: null, message: null };
        }
    }, [bids, asks, quantity, quantityState]);

    const total = useMemo(() => {
        if (ccy2Order) {
            return vwap ? `${totalFormatter.format(orderQuantity)} ${ccy1}` : '-';
        } else {
            return vwap ? `${totalFormatter.format(vwap.multipliedBy(orderQuantity).toNumber())} ${ccy2}` : '-';
        }
    }, [vwap, orderQuantity]);

    return (
        <div className="flex flex-col gap-3 text-sm md:text-base">
            <div className="flex flex-row justify-between gap-2">
                <span>VWAP</span>
                <div>{vwap ? formatPrice(vwap.toNumber()) : '-'}</div>
            </div>
            {message && <div className="text-neutral-400 text-xs md:text-sm italic">{message}</div>}
            <div className="flex flex-row justify-between gap-2">
                <span>Current Best {matchType.toUpperCase()}</span>
                <span className="text-right">
                    {isTrader
                        ? tickerBidAsk
                            ? formatPrice(tickerBidAsk[matchType])
                            : '-'
                        : bidAsk
                        ? formatPrice(bidAsk[matchType])
                        : '-'}
                </span>
            </div>
            <div className="flex flex-row justify-between gap-2">
                <span>Total (approx)</span>
                <span className="text-right">{total}</span>
            </div>
        </div>
    );
};

export default Totals;
