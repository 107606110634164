import {
    OrderRoutingOrderEvent,
    OrderServiceClient
} from '@/compiled_proto/com/celertech/orderrouting/api/notification/OrderServiceProto';
import { HandlingInstruction } from '../compiled_proto/com/celertech/marketdata/api/enums/HandlingInstructionProto';
import { ExecutionType } from '../compiled_proto/com/celertech/orderrouting/api/enums/ExecutionTypeProto';
import { OrderStatus } from '../compiled_proto/com/celertech/orderrouting/api/enums/OrderStatusProto';
import { FxOrderSnapshotDownstreamEvent } from '../compiled_proto/com/celertech/orderrouting/api/order/DownstreamOrderProto';
import { OrderType } from '../compiled_proto/com/celertech/positionmanager/api/enums/OrderTypeProto';
import { ProductType } from '../compiled_proto/com/celertech/positionmanager/api/enums/ProductTypeProto';
import { Side } from '../compiled_proto/com/celertech/positionmanager/api/enums/SideProto';
import { TimeInForceType } from '../compiled_proto/com/celertech/positionmanager/api/enums/TimeInForceTypeProto';
import { BlotterItem, BlotterTrade, OrderHistory } from '../model/blotters';

import { FindAllOrderSnapshotsByOrderId } from '@/compiled_proto/com/celertech/orderrouting/api/order/UpstreamOrderProto';

import instrumentConfig from '@/config/instruments';
import { User } from '@/state/reducers/authSlice';
import { Metadata } from 'nice-grpc-web';
import { v4 as uuidv4 } from 'uuid';

// Orders Blotter: Format BUY, TRADE and FILLED values in green and SELL, REJECTED and CANCELED values in red
// Use either BlotterItem or BlotterTrade
// export const orderStatusColorFunc: CellClassFunc<any> = (params) => {
//     const val = (params.data?.status || '') as keyof typeof OrderStatus;

//     // 'text-blue-700'
//     if (val === 'NEW') return 'text-brand-primary';
//     else if (['PART_FILLED', 'FILLED', 'TRADE'].includes(val)) return 'text-brand-primary';
//     // 'text-yellow-400'
//     else if (val.includes('PENDING')) return 'text-neutral-200';
//     else if (['CANCELED', 'REJECTED'].includes(val)) return 'text-brand-red';
//     else return 'text-neutral-200';
// };

// export const sideColorFunc: CellClassFunc<any> = (params) =>
//     params.data?.side === 'BUY' ? 'text-brand-primary' : 'text-brand-red';

// export const oppositeSideColorFunc: CellClassFunc<any> = (params) =>
//     params.data?.side === 'BUY' ? 'text-brand-red' : 'text-brand-primary';

export function parseBlotterOrder(orderEvent: FxOrderSnapshotDownstreamEvent): BlotterItem {
    const {
        securityId,
        leg,
        currency,
        qty,
        price,
        orderId,
        createdTimestampUtcInMillis,
        updatedTimestampUtcInMillis,
        orderType,
        side,
        timeInForce,
        lastQty,
        cumQty,
        leavesQty,
        lastPx,
        avgPx,
        stopPx,
        orderStatus,
        account,
        trader,
        settlementDate,
        executionType,
        productType,
        handlingInstruction,
        orderMetadata,
        // props for cancel order
        celerClOrderId,
        originalCelerClOrderId,
        onBehalfOfCompId,
        deliverToCompId,
        checkpointTimestamp,
        // onBehalfOfUsername,
        parentOrderId
    } = orderEvent;

    const tenor = leg && leg.length ? leg[0]?.settlementType : undefined;
    let slippage = orderMetadata.find((metadata) => metadata.key == 'SLIPPAGE')?.value;
    if (slippage) slippage = parseFloat(slippage).toString();

    const [ccy1, ccy2] = securityId.split('/');

    // let position;
    // if (currency === ccy1) position = Side[side];
    // else position = Side[side === 1 ? 2 : 1];

    const item = {
        orderId,
        created: parseInt(createdTimestampUtcInMillis),
        updated: parseInt(updatedTimestampUtcInMillis),
        pair: securityId,
        orderType: OrderType[orderType] as keyof typeof OrderType,
        side: Side[side] as keyof typeof Side,
        status: OrderStatus[orderStatus] as keyof typeof OrderStatus,
        executionType: ExecutionType[executionType] as keyof typeof ExecutionType,
        productType: ProductType[productType] as keyof typeof ProductType,
        instructions: HandlingInstruction[handlingInstruction] as keyof typeof HandlingInstruction,
        tif: TimeInForceType[timeInForce] as keyof typeof TimeInForceType,
        price,
        ccy: currency,
        ccy1,
        ccy2,
        qty,
        lastPrice: lastPx,
        lastQty,
        avgPrice: avgPx,
        stopPrice: stopPx,
        cumQty,
        leavesQty,
        account,
        user: trader,
        slippage,
        tenor,
        valueDate: settlementDate,
        // props for cancel order
        celerClOrderId,
        originalCelerClOrderId,
        onBehalfOfCompId,
        deliverToCompId,
        checkpointTimestamp,
        // onBehalfOfUsername,
        parentOrderId,
        metadata: orderMetadata
    };

    return item;
}

export function parseBlotterTrade(orderEvent: FxOrderSnapshotDownstreamEvent): BlotterTrade {
    const {
        securityId,
        orderId,
        updatedTimestampUtcInMillis,
        orderType,
        currency,
        side,
        lastQty,
        lastPx,
        avgPx,
        account,
        trader,
        id,
        orderStatus,
        executionType,
        timeInForce
    } = orderEvent;

    const [ccy1, ccy2] = securityId.split('/');
    const ccy2Order = currency === ccy2;

    let position;
    if (ccy2Order) position = Side[side === 1 ? 2 : 1];
    else position = Side[side];

    const qty1sign = position === 'BUY' ? 1 : -1;
    const qty2sign = position === 'BUY' ? -1 : 1;

    const item: BlotterTrade = {
        orderId,
        executionTime: parseInt(updatedTimestampUtcInMillis),
        executionId: id,
        side: position as keyof typeof Side,
        orderType: OrderType[orderType] as keyof typeof OrderType,
        status: OrderStatus[orderStatus] as keyof typeof OrderStatus,
        executionType: ExecutionType[executionType] as keyof typeof ExecutionType,
        tif: TimeInForceType[timeInForce] as keyof typeof TimeInForceType,
        pair: securityId,
        fillPrice: lastPx,
        avgFillPrice: avgPx,
        ccy: currency,
        ccy1,
        ccy2,
        qty1: (ccy2Order ? lastQty / lastPx : lastQty) * qty1sign,
        qty2: (ccy2Order ? lastQty : lastQty * lastPx) * qty2sign,
        account,
        user: trader,
        fillNo: -1 // dummy to be overridden later,
    };

    return item;
}

export function parseOrderHistory(orderEvent: FxOrderSnapshotDownstreamEvent): OrderHistory {
    const {
        securityId,
        qty,
        price,
        orderId,
        updatedTimestampUtcInMillis,
        orderType,
        currency,
        side,
        timeInForce,
        lastQty,
        cumQty,
        leavesQty,
        lastPx,
        avgPx,
        stopPx,
        orderStatus,
        account,
        trader,
        executionType
    } = orderEvent;

    const [ccy1, ccy2] = securityId.split('/');

    const item: OrderHistory = {
        orderId,
        executionTime: parseInt(updatedTimestampUtcInMillis),
        pair: securityId,
        orderType: OrderType[orderType] as keyof typeof OrderType,
        side: Side[side] as keyof typeof Side,
        status: OrderStatus[orderStatus] as keyof typeof OrderStatus,
        executionType: ExecutionType[executionType] as keyof typeof ExecutionType,
        tif: TimeInForceType[timeInForce] as keyof typeof TimeInForceType,
        ccy: currency,
        ccy1,
        ccy2,
        qty,
        account,
        user: trader,
        price,
        lastPrice: lastPx,
        lastQty,
        avgPrice: avgPx,
        stopPrice: stopPx,
        cumQty,
        leavesQty
    };

    return item;
}

interface FindSnapshotParams {
    historicOrders: BlotterItem[] | OrderRoutingOrderEvent[];
    credentials: User;
    client: OrderServiceClient;
}

export const findSnapshotsFromAllOrders = async ({ historicOrders, credentials, client }: FindSnapshotParams) => {
    let blotterTrades = [];

    const historyPromises = historicOrders.map(async (order: any) => {
        const orderId = order?.fxOrderSnapshotDownstreamEvent?.orderId || order.orderId;

        const findHistoricTradesRequest = FindAllOrderSnapshotsByOrderId.fromPartial({
            clientRequestId: uuidv4(),
            orderId: orderId
        });

        const historicTrades = await client.findAllOrderSnapshotsByOrderId(findHistoricTradesRequest, {
            metadata: Metadata({ 'authorization-token': credentials.authToken })
        });

        const items: BlotterTrade[] = [];

        for (const orderEvent of historicTrades.orders) {
            const { fxOrderSnapshotDownstreamEvent } = orderEvent;

            // if (fxOrderSnapshotDownstreamEvent) items.push(parseBlotterTrade(fxOrderSnapshotDownstreamEvent));
            if (fxOrderSnapshotDownstreamEvent) {
                const orderStatus = fxOrderSnapshotDownstreamEvent.orderStatus;
                if (
                    ![
                        OrderStatus.NEW,
                        OrderStatus.PENDING_NEW,
                        OrderStatus.PENDING_AMEND,
                        OrderStatus.PENDING_CANCEL
                    ].includes(orderStatus)
                ) {
                    items.push(parseBlotterTrade(fxOrderSnapshotDownstreamEvent));
                }
            }
        }

        // Deduce fill number
        items.sort((a, b) => (a.executionTime > b.executionTime ? 1 : -1));
        const fillCounter: Record<string, number> = {};
        items.forEach((item) => {
            if (item.status === 'FILLED' || item.status === 'PART_FILLED') {
                if (fillCounter[item.orderId]) fillCounter[item.orderId] += 1;
                else fillCounter[item.orderId] = 1;
                item.fillNo = fillCounter[item.orderId];
            }
        });

        blotterTrades = [...blotterTrades, ...items] as any;
    });

    await Promise.all(historyPromises);

    return blotterTrades;
};

export const getTradeType = (instrument: string, accountType?: 'NOP' | 'MARGIN') => {
    const config = instrumentConfig[instrument];

    switch (config?.type) {
        case 'FX':
            return 'FX Spot';
        case 'Crypto':
            return accountType === 'NOP' ? 'Crypto Spot' : 'Crypto CFD';
        case 'Index':
            return 'Index CFD';
        case 'Commodity':
            return 'Commodity CFD';
        default:
            return '';
    }
};
