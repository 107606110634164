import ContextMenu from '@/components/common/ContextMenu';
import InstrumentInfoContext from '@/components/common/InstrumentInfoContext';
import Table from '@/components/table/Table';
import { convertedQtyFormatterConfig, defaultColumn, qtyFormatterConfig } from '@/config/config';
import { getTradeType } from '@/helpers/blotterHelper';
import { openPositionColumns } from '@/helpers/openPositionHelper';
import { Modify } from '@/model/common';
import { useAppSelector } from '@/state/hooks';
import { selectCredentials, selectCurrentAccount } from '@/state/reducers/authSlice';
import { PairMap } from '@/state/reducers/marketPairSlice';
import { OpenPosition } from '@/state/reducers/openPositionSlice';
import { store } from '@/state/store';
import useContextMenu from '@/utils/hooks/useContextMenu';
import { useDebouncedValue } from '@/utils/hooks/useDebouncedValue';
import useInterval from '@/utils/hooks/useInterval';
import { translatePair } from '@/utils/symbolMapping';
import {
    ColumnDef,
    ExpandedState,
    getCoreRowModel,
    getExpandedRowModel,
    getFacetedMinMaxValues,
    getFacetedRowModel,
    getFacetedUniqueValues,
    getFilteredRowModel,
    getSortedRowModel,
    useReactTable
} from '@tanstack/react-table';
import BigNumber from 'bignumber.js';
import cn from 'classnames';
import { Fragment, memo, useEffect, useMemo, useState } from 'react';
import { useNumberFormatter } from 'react-aria';

export interface OpenPositionRow
    extends Modify<
        OpenPosition,
        {
            unrealisedPnl: {
                value: number;
                currency: string;
            };
            realisedPnl: {
                value: number;
                currency: string;
            };
            instrument: string;
            tableInstrument: string;
            market: PairMap;
            type: string;
            netQtyPosition: number;
            openPrice: number;
            currentPrice: number;
            settlementDate: string;
            accountCode: string;
            realisedPnLCurrency: string;
        }
    > {}

interface OpenPositionTableProps {
    setCSVData: React.Dispatch<React.SetStateAction<any[]>>;
    onOrderModal: (row: OpenPositionRow) => void;
}

export type PositionFilter = 'Show All' | 'Hide Closed Positions';

const positionFilters: { label: PositionFilter; value: boolean }[] = [
    { label: 'Show All', value: true },
    { label: 'Hide Closed Positions', value: false }
];

const OpenPositionTable = (props: OpenPositionTableProps) => {
    const contextMenu = useContextMenu();

    const { contextMenuX, contextMenuY, contextMenuVisible, handleOpenContextMenu, handleCloseContextMenu } =
        contextMenu;

    const [positionFilter, setPositionFilter] = useState<boolean>(true);
    const [instrumentInfoContext, setInstrumentInfoContext] = useState();

    const { debouncedTableInstance, debouncedOpenPositions, expandedRows } = useOpenPositionsTable({
        ...props,
        positionFilter,
        setInstrumentInfoContext,
        handleOpenContextMenu
    });

    return (
        <Fragment>
            <div className="flex flex-col h-full gap-2">
                <div className="flex">
                    {positionFilters.map((filter, index) => {
                        return (
                            <button
                                key={filter.label}
                                className={cn('text-sm py-1 px-2 text-neutral-200 border', {
                                    'border-brand-primary bg-brand-primary': positionFilter === filter.value,
                                    'border-neutral-600 hover:bg-brand-primary-light hover:border-brand-primary-light':
                                        positionFilter !== filter.value
                                })}
                                onClick={() => setPositionFilter(filter.value)}>
                                {filter.label}
                            </button>
                        );
                    })}
                </div>
                <div className="flex-1 basis-0 overflow-hidden">
                    <MemoizedOpenPositionTable
                        tableInstance={debouncedTableInstance}
                        debouncedOpenPositions={debouncedOpenPositions}
                        expandedRows={expandedRows}
                        positionFilter={positionFilter}
                    />
                </div>
            </div>
            <ContextMenu
                x={contextMenuX}
                y={contextMenuY}
                offset={{ x: 4, y: 4 }}
                visible={contextMenuVisible}
                onClose={handleCloseContextMenu}
                className="!bg-brand-background !w-auto">
                <InstrumentInfoContext market={instrumentInfoContext} handleCloseContextMenu={handleCloseContextMenu} />
            </ContextMenu>
        </Fragment>
    );
};

export default OpenPositionTable;

const MemoizedOpenPositionTable = memo(({ tableInstance, debouncedOpenPositions, expandedRows }: any) => {
    return <Table tableInstance={tableInstance} isLoadingTableContent={false} />;
});

MemoizedOpenPositionTable.displayName = 'OpenPositionTable';

const groupRowsByInstrument = (rows: OpenPositionRow[]) => {
    const groupedMap = new Map<string, any>();

    rows.forEach((item) => {
        const {
            instrument,
            tableInstrument,
            type,
            market,
            netQtyPosition,
            openPrice,
            currentPrice,
            unrealisedPnl,
            realisedPnl,
            settlementDate,
            accountCode
        } = item;

        if (groupedMap.has(tableInstrument)) {
            const group = groupedMap.get(tableInstrument)!;

            const convertedPosition = BigNumber(netQtyPosition).multipliedBy(openPrice).toNumber();
            const sumOfNetPosition = BigNumber(group.netQtyPosition).plus(netQtyPosition).toNumber();

            group.openPrice =
                sumOfNetPosition !== 0
                    ? BigNumber(convertedPosition)
                          .plus(BigNumber(group.openPrice).multipliedBy(group.netQtyPosition))
                          .dividedBy(sumOfNetPosition)
                          .toNumber()
                    : '-';
            group.currentPrice = currentPrice;
            group.netQtyPosition = BigNumber(group.netQtyPosition).plus(netQtyPosition).toNumber();

            group.unrealisedPnl = {
                value: BigNumber(group.unrealisedPnl?.value || 0)
                    .plus(unrealisedPnl.value || 0)
                    .toNumber(),
                currency: unrealisedPnl.currency
            };
            group.realisedPnl = {
                value: BigNumber(group.realisedPnl?.value || 0)
                    .plus(realisedPnl.value || 0)
                    .toNumber(),
                currency: realisedPnl.currency
            };

            group.subRows.push({
                instrument,
                tableInstrument,
                type,
                netQtyPosition,
                openPrice,
                currentPrice,
                unrealisedPnl,
                realisedPnl,
                settlementDate,
                accountCode
            });
        } else {
            groupedMap.set(tableInstrument, {
                instrument,
                tableInstrument,
                market,
                type,
                netQtyPosition,
                openPrice,
                currentPrice,
                unrealisedPnl,
                realisedPnl,
                settlementDate,
                accountCode,
                subRows: [
                    {
                        instrument,
                        tableInstrument,
                        type,
                        netQtyPosition,
                        openPrice,
                        currentPrice,
                        unrealisedPnl,
                        realisedPnl,
                        settlementDate,
                        accountCode
                    }
                ]
            });
        }
    });

    return Array.from(groupedMap.values());
};

interface UseOpenPositionsTableProps extends OpenPositionTableProps {
    positionFilter: boolean;
    setInstrumentInfoContext: React.Dispatch<React.SetStateAction<any>>;
    handleOpenContextMenu: (row: any, x: number, y: number) => void;
}

const useOpenPositionsTable = (props: UseOpenPositionsTableProps) => {
    const { positionFilter, setCSVData, onOrderModal, setInstrumentInfoContext, handleOpenContextMenu } = props;

    const credentials = useAppSelector(selectCredentials);
    const currentAccount = useAppSelector(selectCurrentAccount);
    const formatQty = useNumberFormatter(qtyFormatterConfig);
    const formatConvertedQty = useNumberFormatter(convertedQtyFormatterConfig);

    const [debouncedOpenPositions, setDebouncedOpenPositions] = useState<OpenPosition[]>([]);
    const [expandedRows, setExpandedRows] = useState<ExpandedState>({});

    const currentAccountProperties = useMemo(
        () => credentials?.accounts.find((account) => account.code === currentAccount)?.properties || [],
        [credentials, currentAccount]
    );

    const accountType = useMemo(
        () => currentAccountProperties.find((property) => property.key === 'TYPE')?.value as 'NOP' | 'MARGIN',
        [currentAccountProperties]
    );

    const columns: ColumnDef<OpenPositionRow>[] = useMemo(() => {
        return openPositionColumns({
            onOrderModal,
            formatQty,
            formatConvertedQty,
            setInstrumentInfoContext,
            handleOpenContextMenu
        });
    }, [onOrderModal, formatQty, formatConvertedQty, setInstrumentInfoContext, handleOpenContextMenu]);

    const formattedOpenPositions: OpenPositionRow[] = useMemo(() => {
        const returnValues = debouncedOpenPositions
            .filter(({ positionUpdate }) => positionUpdate?.accountCode === currentAccount)
            .filter(({ positionUpdate }) => positionFilter || positionUpdate?.netQtyPosition !== 0)
            .map((openPosition) => {
                const { openPrice, positionUpdate, unrealisedPnl } = openPosition;
                const bidAsk = store.getState().marketPair.bidAsk[positionUpdate?.securityCode || ''];
                const currentPrice = bidAsk ? BigNumber(bidAsk.ask).plus(bidAsk.bid).div(2).toNumber() : '-';
                const tableInstrument = translatePair(positionUpdate?.securityCode || '');

                const market = {
                    celer: positionUpdate?.securityCode || '',
                    netdania: positionUpdate?.securityCode || '',
                    show: tableInstrument
                } as PairMap;

                return {
                    ...openPosition.positionUpdate,
                    type: getTradeType(positionUpdate?.securityCode || '', accountType) || '',
                    instrument: positionUpdate?.securityCode || '',
                    market,
                    tableInstrument,
                    openPrice,
                    unrealisedPnl: {
                        value: unrealisedPnl,
                        currency: positionUpdate?.realisedPnLCurrency || ''
                    },
                    realisedPnl: {
                        value: positionUpdate?.realisedPnl,
                        currency: positionUpdate?.realisedPnLCurrency || ''
                    },
                    currentPrice,
                    settlementDate: positionUpdate?.settlementDate || '-'
                };
            }) as OpenPositionRow[];

        returnValues.sort((a, b) => {
            if (a.tableInstrument < b.tableInstrument) return -1;
            if (a.tableInstrument > b.tableInstrument) return 1;
            return 0;
        });

        return groupRowsByInstrument(returnValues);
    }, [debouncedOpenPositions, currentAccount, accountType, positionFilter]);

    const tableInstance = useReactTable({
        data: formattedOpenPositions,
        columns,
        state: { expanded: expandedRows },
        defaultColumn,
        enableColumnResizing: true,
        columnResizeMode: 'onChange',
        globalFilterFn: 'includesString',
        getCoreRowModel: getCoreRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        getSortedRowModel: getSortedRowModel(),
        getFacetedRowModel: getFacetedRowModel(),
        getFacetedUniqueValues: getFacetedUniqueValues(),
        getFacetedMinMaxValues: getFacetedMinMaxValues(),
        onExpandedChange: setExpandedRows,
        getExpandedRowModel: getExpandedRowModel(),
        getSubRows: (row: any) => row.subRows,
        enableRowSelection: true
    });

    const [debouncedTableInstance] = useDebouncedValue(tableInstance, 5000);

    useEffect(() => {
        setCSVData(formattedOpenPositions);
    }, [formattedOpenPositions]);

    useInterval(
        () => {
            setDebouncedOpenPositions(store.getState().openPosition.openPositions);
        },
        5000,
        true
    );

    return { debouncedTableInstance, debouncedOpenPositions, expandedRows };
};
