import { convertedQtyFormatterConfig } from '@/config/config';
import { mergeRefs, parseShortFormNumber } from '@/utils/common';
import BigNumber from 'bignumber.js';
import cn from 'classnames';
import { ComponentProps, forwardRef, useEffect, useRef } from 'react';
import { useLocale, useNumberField } from 'react-aria';
import { useFormContext } from 'react-hook-form';
import { useNumberFieldState } from 'react-stately';

interface TickerQuantityInputProps extends ComponentProps<'input'> {
    name: string;
    label?: string;
    min?: string | number;
    step?: number;
    className?: any;
    active_ccy: string;
    instrumentType: 'Crypto CFD' | 'FX Spot' | 'Index CFD' | 'Comm CFD' | '';
    currencyAssetName: string;
    ccy2_enabled: boolean;
    switchCcy: () => void;
    onChangeCallback?: (value: number | null) => void;
}

const TickerQuantityInput = forwardRef<HTMLInputElement, TickerQuantityInputProps>((props, ref) => {
    const {
        label,
        active_ccy,
        instrumentType,
        currencyAssetName,
        ccy2_enabled,
        switchCcy,
        onChangeCallback,
        ...inputProps
    } = props;
    const { name, min = 0, step = 1, disabled = false, className } = inputProps;
    const { getValues, setValue, watch } = useFormContext();

    const inputRef = useRef(null);
    const { locale } = useLocale();

    const state = useNumberFieldState({ locale, formatOptions: convertedQtyFormatterConfig });
    const {
        labelProps,
        groupProps,
        inputProps: ariaInputProps
    } = useNumberField({ label: label || name }, state, inputRef);

    const value = watch(name);

    useEffect(() => {
        if (value !== state.numberValue && !isNaN(state.numberValue)) {
            onChangeCallback?.(state.numberValue);
            setValue(name, state.numberValue, { shouldValidate: true, shouldDirty: true });
        } else if (value === null && isNaN(state.numberValue)) {
            onChangeCallback?.(null);
            setValue(name, null);
        }
    }, [state.numberValue]);

    useEffect(() => {
        if (value !== state.numberValue) {
            onChangeCallback?.(value);
            state.setNumberValue(value);
        }
    }, [value]);

    // useEffect(() => {
    //     if (defaultValue) setValue(name, defaultValue, { shouldValidate: true, shouldDirty: true });
    // }, [defaultValue]);

    return (
        <div {...groupProps} className="relative flex flex-1">
            <label {...labelProps} className="block text-sm font-medium text-neutral-200">
                {label}
            </label>
            <input
                {...ariaInputProps}
                ref={mergeRefs(inputRef, ref)}
                value={state.inputValue}
                autoComplete="off"
                onKeyDown={(e) => {
                    if (step >= 100_000 && ['k', 'm'].includes(e.key)) {
                        e.preventDefault();
                        const val = parseShortFormNumber(state.numberValue, e.key);
                        if (!isNaN(val)) {
                            setValue(name, val, { shouldValidate: true, shouldDirty: true });
                            state.commit();
                        }
                    }
                }}
                className={cn(
                    'appearance-none block w-full px-2 py-1 bg-brand-background-dark border border-neutral-700 text-sm placeholder-neutral-500 focus:outline-none',
                    { 'text-neutral-400 cursor-not-allowed': disabled },
                    { 'text-neutral-200': !className },
                    className
                )}
                placeholder="0"
                // placeholder={min.toString()}
                disabled={disabled}
            />
            <div className="absolute right-12 flex flex-col h-full justify-center text-[8px] text-neutral-200 w-5">
                <button
                    type="button"
                    className={cn('-mb-0.5 px-0', {
                        'text-neutral-400 cursor-not-allowed': disabled,
                        'hover:text-brand-primary-light': !disabled
                    })}
                    onClick={() => {
                        if (!disabled) {
                            const prevValue = +(getValues(name) || 0);
                            const nextValue = BigNumber(prevValue).plus(step).toNumber();
                            setValue(name, nextValue, { shouldValidate: true });
                        }
                    }}>
                    &#x25B2;
                </button>
                <button
                    type="button"
                    className={cn('-mt-0.5 px-0', {
                        'text-neutral-400 cursor-not-allowed': disabled,
                        'hover:text-brand-primary-light': !disabled
                    })}
                    onClick={() => {
                        if (!disabled) {
                            const prevValue = +(getValues(name) || 0);
                            const nextValue = BigNumber(prevValue).minus(step).toNumber();
                            if (nextValue <= +min) setValue(name, min, { shouldValidate: true });
                            else setValue(name, nextValue, { shouldValidate: true });
                        }
                    }}>
                    &#x25BC;
                </button>
            </div>
            <div
                className={cn('absolute right-2 flex flex-col h-full justify-center text-sm', {
                    'text-neutral-400 cursor-not-allowed': !ccy2_enabled,
                    'text-neutral-200 cursor-pointer hover:text-brand-primary-light': ccy2_enabled
                })}
                onClick={() => {
                    if (ccy2_enabled) {
                        setValue(name, null, { shouldValidate: false });
                        switchCcy();
                    }
                }}>
                {['Index CFD', 'Comm CFD'].includes(instrumentType) ? 'Qty' : currencyAssetName}
            </div>
        </div>
    );
});

TickerQuantityInput.displayName = 'TickerQuantityInput';

export default TickerQuantityInput;
