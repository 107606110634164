import Divider from '@/components/common/Divider';
import InstrumentInfo from '@/components/common/InstrumentInfo';
import { tickerIdleTimer } from '@/config/config';
import { Modify } from '@/model/common';
import {
    addSubscription,
    removeSubscription,
    selectCurrentWatchList,
    selectTraderWatchLists,
    setShouldReinstateWatchlist
} from '@/state/reducers/traderMarketSlice';
import useTicker from '@/utils/hooks/useTicker';
import cn from 'classnames';
import { CSSProperties, useMemo } from 'react';
import {
    MdAddCircleOutline,
    MdOutlineRemoveRedEye,
    MdRemoveCircleOutline,
    MdStar,
    MdStarOutline
} from 'react-icons/md';
import { useAppDispatch, useAppSelector } from '../../../state/hooks';
import {
    PairMap,
    addFavouritePair,
    removeFavouritePair,
    selectActiveMarketPair,
    setActivePair
} from '../../../state/reducers/marketPairSlice';
import { GetColorIndexesReturn, GetPipIndexesReturn } from '../../../utils/hooks/useInstrument';
import { useTickerIdle } from '../../../utils/hooks/useTickerIdle';
import HighlightedPips from '../../common/HighlightedPips';

interface UserMarketProps {
    market: PairMap;
    userFavouriteMarketsCeler: string[];
    style: CSSProperties;
    isActive?: boolean;
    onClick?: () => void;
    onActive?: () => void;
}
const UserMarket = (props: UserMarketProps) => {
    const { market, style, isActive, onClick, onActive } = props;
    const dispatch = useAppDispatch();
    const activePair = useAppSelector(selectActiveMarketPair);
    const watchLists = useAppSelector(selectTraderWatchLists);
    const selectedWatchList = useAppSelector(selectCurrentWatchList);
    const watchList = useMemo(() => selectedWatchList || watchLists[0], [watchLists, selectedWatchList]);

    const isActivePair = useMemo(() => {
        return activePair.celer === market.celer;
    }, [activePair, market]);

    const isInWatchlist = useMemo(() => {
        return watchList.markets.some((m) => m.celer === market.celer);
    }, [watchList]);

    return (
        <div
            style={style}
            className={cn('hover:bg-neutral-700', {
                'bg-brand-background-dark border-y border-neutral-700 pt-[1px]': isActive
            })}>
            <div className={cn('cursor-pointer flex text-xs py-1.5 px-2 text-neutral-200')} onClick={onActive}>
                <UserFavourite {...props} />
                <BidAsks {...props} />
            </div>
            {isActive && <Divider className="bg-neutral-700" />}
            {isActive && (
                <div className="flex text-neutral-200 text-xs h-6">
                    <div
                        className={cn('flex justify-center items-center gap-1 whitespace-nowrap px-2', {
                            'cursor-pointer bg-neutral-700 text-neutral-200 hover:bg-neutral-600': !isActivePair,
                            'cursor-not-allowed bg-neutral-700 text-neutral-300': isActivePair
                        })}
                        onClick={() => {
                            dispatch(setActivePair(market));
                            onClick && onClick();
                        }}>
                        <MdOutlineRemoveRedEye className="w-3.5 h-3.5" />
                        <span className="mt-0.5">View Charts</span>
                    </div>
                    {!isInWatchlist ? (
                        <div
                            className="flex justify-center items-center gap-1 cursor-pointer whitespace-nowrap bg-brand-primary-dark hover:bg-brand-primary px-2"
                            onClick={() => {
                                dispatch(addSubscription({ id: watchList.id, market }));
                                dispatch(setShouldReinstateWatchlist({ mode: 'add', market }));
                            }}>
                            <MdAddCircleOutline className="w-3.5 h-3.5" />
                            <span className="mt-0.5">Add Ticker</span>
                        </div>
                    ) : (
                        <div
                            className="flex justify-center items-center gap-1 cursor-pointer whitespace-nowrap bg-brand-red-dark hover:bg-brand-red px-2"
                            onClick={() => {
                                dispatch(removeSubscription({ id: watchList.id, market }));
                                dispatch(setShouldReinstateWatchlist({ mode: 'remove', market }));
                            }}>
                            <MdRemoveCircleOutline className="w-3.5 h-3.5" />
                            <span className="mt-0.5">Remove Ticker</span>
                        </div>
                    )}
                </div>
            )}
        </div>
    );
};

export default UserMarket;

type UserFavouriteProps = Modify<
    Omit<UserMarketProps, 'style'>,
    {
        isMobileSubHeader?: boolean;
    }
>;

export const UserFavourite = ({ market, userFavouriteMarketsCeler, isMobileSubHeader }: UserFavouriteProps) => {
    const dispatch = useAppDispatch();

    const isFavourite = useMemo(() => {
        return userFavouriteMarketsCeler.includes(market.celer);
    }, [market, userFavouriteMarketsCeler]);

    return (
        <div className="min-w-[110px] w-1/3 flex items-center">
            <div
                className="mr-1 cursor-pointer -mt-0.5"
                onClick={(e) => {
                    if (isFavourite) dispatch(removeFavouritePair(market));
                    else dispatch(addFavouritePair(market));

                    e.stopPropagation();
                }}>
                {isFavourite ? (
                    <MdStar className="h-4 w-4 fill-yellow-500" />
                ) : (
                    <MdStarOutline className="h-4 w-4 fill-neutral-100" />
                )}
            </div>
            <div>{market.show}</div>
            <div className="ml-1">
                <InstrumentInfo
                    market={market}
                    offsetOverride={{
                        mainAxis: 0,
                        crossAxis: isMobileSubHeader ? -80 : -80
                    }}
                />
            </div>
        </div>
    );
};

const BidAsks = ({ market }: UserMarketProps) => {
    const { formattedBid, formattedAsk, spread, bidHighlights, askHighlights } = useTicker(market);
    return (
        <>
            <div className="min-w-[80px] flex-1 flex items-center justify-start">
                {formattedBid && <Highlights ticker={formattedBid} highlights={bidHighlights} />}
            </div>
            <div className="min-w-[20px] flex items-center justify-center px-1">{spread || '-'}</div>
            <div className="min-w-[80px] flex-1 flex items-center justify-end">
                {formattedAsk && <Highlights ticker={formattedAsk} highlights={askHighlights} />}
            </div>
        </>
    );
};

interface HighlightsProps {
    ticker: GetPipIndexesReturn;
    highlights?: GetColorIndexesReturn;
}

export const Highlights = ({ ticker, highlights }: HighlightsProps) => {
    // check if either bid or ask in this ticker is stale for more than 5 seconds.
    // if stale for more than 5 seconds, ticker is idle. if idle, use default colour
    const tickerIdle = useTickerIdle(tickerIdleTimer, highlights);
    return (
        <HighlightedPips
            price={ticker.price}
            boldIndexes={ticker.pipIndexes}
            colorIndexes={!tickerIdle ? highlights?.colorIndexes : []}
            color={!tickerIdle ? highlights?.color : 'text-neutral-200'}
        />
    );
};
