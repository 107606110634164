import { TimeInForceType } from '@/compiled_proto/com/celertech/orderrouting/api/enums/TimeInForceTypeProto';
import Divider from '@/components/common/Divider';
import Tooltip from '@/components/common/Tooltip';
import FormBody from '@/components/form/components/FormBody';
import FormCurrency from '@/components/form/components/FormCurrency';
import FormFooter from '@/components/form/components/FormFooter';
import FormOrderTypeSwitch from '@/components/form/components/FormOrderTypeSwitch';
import FormSubmit from '@/components/form/components/FormSubmit';
import Totals from '@/components/form/components/Totals';
import { LimitOrderFormInput, LimitOrderFormValues, limitOrderSchema } from '@/components/form/schema/limitOrderSchema';
import ExecutionInputController from '@/components/inputs/ExecutionInputController';
import RHFNumberInput from '@/components/inputs/RHFNumberInput';
import Select from '@/components/inputs/Select';
import SlippageInput from '@/components/inputs/SlippageInput';
import { submitLimitOrder } from '@/services/OrderService';
import { useAppSelector } from '@/state/hooks';
import { selectCredentials, selectCurrentAccount } from '@/state/reducers/authSlice';
import { useDidUpdate } from '@/utils/hooks/useDidUpdate';
import useLimitOrder from '@/utils/hooks/useLimitOrder';
import { UseOrderExecutionTraderReturn } from '@/utils/hooks/useOrderExecutionTrader';
import useScreenSizes from '@/utils/hooks/useScreenSizes';
import { yupResolver } from '@hookform/resolvers/yup';
import BigNumber from 'bignumber.js';
import { useEffect } from 'react';
import { isTablet } from 'react-device-detect';
import { FormProvider, useForm } from 'react-hook-form';

const durationOptions: { label: keyof typeof TimeInForceType; value: TimeInForceType }[] = [
    { label: 'DAY', value: TimeInForceType.DAY },
    { label: 'IOC', value: TimeInForceType.IOC },
    { label: 'FOK', value: TimeInForceType.FOK },
    { label: 'GTC', value: TimeInForceType.GTC }
];

const defaultValues: Partial<LimitOrderFormInput> = {
    limitPrice: null,
    quantity: null,
    duration: durationOptions[0],
    slippage: '0.00000'
};

interface LimitOrderFormProps extends UseOrderExecutionTraderReturn {
    onClose?: () => void;
    closeOnSubmit?: boolean;
}

function LimitOrderForm(props: LimitOrderFormProps) {
    const { onClose, closeOnSubmit = false, ...orderExecutionProps } = props;
    const {
        ccy1,
        ccy2,
        sideState,
        currencyState,
        orderBook,
        instrument,
        activeCurrencyConfig,
        activeTicker,
        isCcy2Order
    } = orderExecutionProps;

    const credentials = useAppSelector(selectCredentials);
    const currentAccount = useAppSelector(selectCurrentAccount);

    const screen = useScreenSizes();

    const [side, setSide] = sideState;
    const [currency, setCurrency] = currencyState;

    const { asks, bids } = orderBook;
    const { pip_size, defaultSlippage } = instrument;
    const { min_order_size, order_decimals, increments } = activeCurrencyConfig;

    const form = useForm<LimitOrderFormInput>({
        defaultValues,
        mode: 'onChange',
        resolver: yupResolver(
            limitOrderSchema({
                min_order_size,
                order_decimals,
                currencyOut: currency,
                currencyPair: activeTicker.show
            })
        )
    });

    const {
        register,
        handleSubmit,
        formState: { isSubmitting, isValid }
    } = form;

    const onSubmit = async (data: LimitOrderFormInput, e) => {
        const dataValidated = data as LimitOrderFormValues;
        const slippage = dataValidated.slippage
            ? BigNumber(dataValidated.slippage)
                  .dividedBy(Math.pow(10, pip_size || 0))
                  .toString()
            : '0';
        if (dataValidated.quantity && credentials) {
            if (closeOnSubmit) onClose?.();
            await submitLimitOrder(
                {
                    securityId: activeTicker.celer,
                    currencyOut: currency,
                    quantity: dataValidated.quantity,
                    limitPrice: dataValidated.limitPrice,
                    timeInForce: dataValidated.duration.value as TimeInForceType,
                    slippage,
                    side
                },
                credentials,
                currentAccount
            );
        }
    };

    const onError = (errors) => console.error(errors);

    const { limitPriceTooltip, isRestingLimitOrder, resetDefault } = useLimitOrder(form, props, '');

    useDidUpdate(() => resetDefault(), [currency]);

    useEffect(() => {
        resetDefault();
    }, [activeTicker.celer]);

    return (
        <FormProvider {...form}>
            <form
                onSubmit={handleSubmit(onSubmit, onError)}
                className="relative flex flex-col h-full w-full text-neutral-200">
                <FormBody side={side} mode="Modal">
                    <FormOrderTypeSwitch initialValue={side} onChange={setSide} />
                    <FormCurrency {...props} mode="Trader" currency={currency} setCurrency={setCurrency} />
                    <div>
                        <Tooltip content={limitPriceTooltip || ''} className="-top-16 right-0 w-2/3">
                            <RHFNumberInput
                                label="Limit Price *"
                                placeholder="Limit Price"
                                {...register('limitPrice')}
                                disabled={!isRestingLimitOrder}
                                className={{
                                    'text-brand-red': limitPriceTooltip,
                                    'text-neutral-200': !limitPriceTooltip
                                }}
                                step={BigNumber(1)
                                    .dividedBy(Math.pow(10, pip_size || 0))
                                    .toNumber()}
                            />
                        </Tooltip>
                        {(screen.mobile || screen.tablet || isTablet) && (
                            <span className="text-brand-red italic">{limitPriceTooltip}</span>
                        )}
                    </div>
                    <ExecutionInputController name="duration" label="Duration *">
                        <Select type="execution" options={durationOptions} />
                    </ExecutionInputController>
                    <RHFNumberInput
                        {...register('quantity')}
                        label="Quantity *"
                        placeholder="Quantity"
                        min={min_order_size}
                        step={increments}
                    />
                    <SlippageInput
                        {...register('slippage')}
                        label="Slippage (pips)"
                        placeholder="Slippage (pips)"
                        disabled={isRestingLimitOrder}
                        defaultSlippage={defaultSlippage}
                        step={0.1}
                    />
                    <Totals
                        activePair={activeTicker}
                        side={side}
                        ccy1={ccy1}
                        ccy2={ccy2}
                        ccy2Order={isCcy2Order}
                        bids={bids}
                        asks={asks}
                        isTrader
                    />
                </FormBody>
                <Divider />
                <FormFooter mode="Modal">
                    <FormSubmit side={side} isValid={isValid} disabled={isSubmitting || !isValid} className="left-6" />
                </FormFooter>
            </form>
        </FormProvider>
    );
}

export default LimitOrderForm;
