import { Side } from '@/compiled_proto/com/celertech/marketdata/api/enums/SideProto';
import { useAppSelector } from '@/state/hooks';
import { selectTradingSettings } from '@/state/reducers/settingSlice';
import { useDebouncedOneClickTrading } from '@/utils/hooks/useDebouncedOneClickTrading';
import cn from 'classnames';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { MdCheckCircle, MdLock } from 'react-icons/md';
import Tooltip from '../../common/Tooltip';

interface FormSubmitProps {
    side: Side;
    isValid: boolean;
    disabled: boolean;
    className?: string;
}

// put this in window.config
const expirationDuration = 5000;

const FormSubmit = ({ side, isValid, disabled, className = '' }: FormSubmitProps) => {
    const trading = useAppSelector(selectTradingSettings);
    const isTwoClickTrading = trading?.twoClickTrading?.value || false;

    const [locked, setLocked] = useState<boolean>(!!isTwoClickTrading);
    const [twoClickExpirationTimer, setTwoClickExpirationTimer] = useState<number>(expirationDuration);

    const onDebouncedOneClick = useDebouncedOneClickTrading(setLocked, 300);

    const tooltip = useMemo(() => {
        if (!isValid) {
            return 'Please ensure all the inputs are valid';
        } else {
            if (locked && isTwoClickTrading) {
                return 'Click once to enable button, and again to place order';
            } else if (!locked) {
                return 'Click to place order';
            }
        }
    }, [isValid, locked]);

    const onOneClickHandler = useCallback(
        (event) => {
            onDebouncedOneClick(false);
            if (!locked) {
                setLocked(true);
            } else {
                event.preventDefault();
            }
        },
        [locked]
    );

    const onTwoClickHandler = useCallback(
        (event) => {
            if (locked) {
                setLocked(false);
                setTwoClickExpirationTimer(5000);
                event.preventDefault();
            } else {
                setLocked(true);
            }
        },
        [locked]
    );

    useEffect(() => {
        setLocked(!!isTwoClickTrading);
    }, [isTwoClickTrading]);

    useEffect(() => {
        if (!locked && isTwoClickTrading) {
            const timer = setTimeout(() => setTwoClickExpirationTimer(twoClickExpirationTimer - 1000), 1000);
            if (twoClickExpirationTimer <= 0) {
                clearTimeout(timer);
                setLocked(true);
            }
        }
    }, [locked, isTwoClickTrading, twoClickExpirationTimer]);

    return (
        <Tooltip content={tooltip} className="max-w-fit">
            <button
                type="submit"
                // https://tailwindcss.com/docs/content-configuration#dynamic-class-names
                className={cn(
                    'w-full flex justify-center items-center py-2 px-4 border border-transparent rounded-md shadow-sm',
                    'text-sm text-white font-medium focus:outline-none focus:ring-2 transition-colors uppercase',
                    {
                        [`bg-brand-primary hover:bg-brand-primary-light focus:ring-brand-primary`]:
                            !disabled && !locked && side === Side.BUY,
                        [`bg-brand-red hover:bg-brand-red-light focus:ring-brand-red`]:
                            !disabled && !locked && side !== Side.BUY,
                        [`border-brand-primary hover:border-brand-primary-light`]:
                            !disabled && locked && side === Side.BUY,
                        [`border-brand-red hover:border-brand-red-light`]: !disabled && locked && side !== Side.BUY,
                        'bg-gray-700 hover:bg-gray-600 focus:ring-gray-600 cursor-not-allowed': disabled
                    }
                )}
                onClick={(e) => {
                    if (!isTwoClickTrading) onOneClickHandler(e);
                    else onTwoClickHandler(e);
                }}
                disabled={disabled}>
                {isTwoClickTrading && (
                    <span
                        className={cn('absolute', {
                            'left-4': !className,
                            [className]: className
                        })}>
                        {locked || !isValid ? <MdLock className="w-4 h-4" /> : <MdCheckCircle className="w-5 h-5" />}
                    </span>
                )}
                <span>{Side[side]}</span>
            </button>
        </Tooltip>
    );
};

export default FormSubmit;
