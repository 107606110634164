import { OrderType } from '@/compiled_proto/com/celertech/positionmanager/api/enums/OrderTypeProto';
import { TimeInForceType } from '@/compiled_proto/com/celertech/positionmanager/api/enums/TimeInForceTypeProto';
import { FetchingStatus } from '@/model/fetchingStatus';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { v4 as uuidv4 } from 'uuid';
import { RootState } from '../store';

export interface TraderMarket {
    celer: string;
    show: string;
    netdania: string;
    tickerState?: {
        quantity: null | number;
        orderType: OrderType;
        duration: TimeInForceType;
    };
}

export interface TraderMarketState {
    status: FetchingStatus;
    watchlists: {
        id: string;
        name: string;
        markets: TraderMarket[];
    }[];
    selectedWatchList?: {
        id: string;
        label: string;
        value: string;
        markets: TraderMarket[];
    };
    shouldReinstateWatchlist?: false | { mode: 'add' | 'remove'; market: TraderMarket };
}

const defaultTickerState = {
    quantity: null,
    orderType: OrderType.MARKET,
    duration: TimeInForceType.IOC
};

const initialState: TraderMarketState = {
    status: 'idle',
    watchlists: [
        {
            id: uuidv4(),
            name: 'Watchlist',
            markets: [
                { celer: 'BTC/USD', show: 'BTC/USD', netdania: 'BTC/USD', tickerState: defaultTickerState },
                { celer: 'ETH/USD', show: 'ETH/USD', netdania: 'ETH/USD', tickerState: defaultTickerState },
                { celer: 'XRP/USD', show: 'XRP/USD', netdania: 'XRP/USD', tickerState: defaultTickerState },
                { celer: 'EUR/USD', show: 'EUR/USD', netdania: 'EUR/USD', tickerState: defaultTickerState },
                { celer: 'XAU/USD', show: 'XAU/USD', netdania: 'XAU/USD', tickerState: defaultTickerState },
                { celer: 'USD/JPY', show: 'USD/JPY', netdania: 'USD/JPY', tickerState: defaultTickerState },
                { celer: 'GEM/EUR', show: 'GEM/EUR', netdania: 'GEM/EUR', tickerState: defaultTickerState },
                { celer: 'DJI/USD', show: 'DJI/USD', netdania: 'DJI/USD', tickerState: defaultTickerState },
                { celer: 'UKI/GBP', show: 'UKI/GBP', netdania: 'UKI/GBP', tickerState: defaultTickerState }
            ]
        }
    ],
    selectedWatchList: undefined,
    shouldReinstateWatchlist: undefined
};

export const traderMarketSlice = createSlice({
    name: 'traderMarket',
    initialState,
    reducers: {
        addWatchList: (state, action: PayloadAction<{ name: string }>) => {
            const newWatchList = {
                id: uuidv4(),
                name: action.payload.name,
                markets: []
            };

            state.watchlists = [...state.watchlists, newWatchList];
            state.selectedWatchList = {
                id: newWatchList.id,
                value: newWatchList.id,
                label: newWatchList.name,
                markets: newWatchList.markets
            };
        },
        editWatchList: (state, action: PayloadAction<{ id: string; name: string }>) => {
            const index = state.watchlists.findIndex((watchlist) => watchlist.id === action.payload.id);
            const watchlist = state.watchlists[index];

            if (watchlist) {
                const updatedWatchList = {
                    id: action.payload.id,
                    name: action.payload.name,
                    markets: watchlist.markets
                };

                state.watchlists[index] = updatedWatchList;
                state.selectedWatchList = {
                    id: updatedWatchList.id,
                    value: updatedWatchList.id,
                    label: updatedWatchList.name,
                    markets: updatedWatchList.markets
                };
            }
        },
        deleteWatchList: (state, action: PayloadAction<{ id: string }>) => {
            state.watchlists = state.watchlists.filter((watchlist) => watchlist.id !== action.payload.id);
            const selected = state.watchlists[0];

            state.selectedWatchList = {
                id: selected.id,
                value: selected.id,
                label: selected.name,
                markets: selected.markets
            };
        },
        arrangeWatchList: (state, action: PayloadAction<{ id: string; markets: TraderMarket[] }>) => {
            const index = state.watchlists.findIndex((watchlist) => watchlist.id === action.payload.id);
            const watchlist = state.watchlists[index];

            if (watchlist) {
                const updatedWatchList = {
                    id: action.payload.id,
                    name: watchlist.name,
                    markets: action.payload.markets
                };

                state.watchlists[index] = updatedWatchList;
                state.selectedWatchList = {
                    id: updatedWatchList.id,
                    value: updatedWatchList.id,
                    label: updatedWatchList.name,
                    markets: updatedWatchList.markets
                };
            }
        },
        editWatchListMarket: (state, action: PayloadAction<{ market: TraderMarket }>) => {
            if (state.selectedWatchList) {
                const { market } = action.payload;
                const marketToUpdate = state.selectedWatchList.markets.findIndex((m) => m.celer === market.celer);
                const index = state.watchlists.findIndex((watchlist) => watchlist.id === state.selectedWatchList?.id);

                if (marketToUpdate > -1) {
                    const updatedMarketList = [...state.selectedWatchList.markets];
                    updatedMarketList[marketToUpdate].tickerState = market.tickerState;
                    state.selectedWatchList.markets = updatedMarketList;
                    state.watchlists[index].markets = updatedMarketList;
                }
            } else if (state.watchlists[0]) {
                const { market } = action.payload;
                const marketToUpdate = state.watchlists[0].markets.findIndex((m) => m.celer === market.celer);

                if (marketToUpdate > -1) {
                    const updatedMarketList = [...state.watchlists[0].markets];
                    updatedMarketList[marketToUpdate].tickerState = market.tickerState;
                    state.watchlists[0].markets = updatedMarketList;
                }
            }
        },
        setSelectedWatchList: (state, action: PayloadAction<TraderMarketState['selectedWatchList']>) => {
            state.selectedWatchList = action.payload;
        },
        setShouldReinstateWatchlist: (state, action: PayloadAction<TraderMarketState['shouldReinstateWatchlist']>) => {
            state.shouldReinstateWatchlist = action.payload;
        },
        addSubscription: (state, action: PayloadAction<{ id: string; market: TraderMarket }>) => {
            const addedMarket = action.payload.market;
            const index = state.watchlists.findIndex((watchlist) => watchlist.id === action.payload.id);

            if (index !== -1) {
                const updatedMarkets = [
                    ...state.watchlists[index].markets,
                    { ...addedMarket, tickerState: defaultTickerState }
                ];
                state.watchlists[index].markets = updatedMarkets;
                state.selectedWatchList = {
                    id: state.watchlists[index].id,
                    value: state.watchlists[index].id,
                    label: state.watchlists[index].name,
                    markets: updatedMarkets
                };
            }
        },
        removeSubscription: (state, action: PayloadAction<{ id: string; market: TraderMarket }>) => {
            const removeMarket = action.payload.market;
            const index = state.watchlists.findIndex((watchlist) => watchlist.id === action.payload.id);

            if (index !== -1) {
                const updatedMarkets = state.watchlists[index].markets.filter(
                    (market) => market.celer !== removeMarket.celer
                );
                state.watchlists[index].markets = updatedMarkets;
                state.selectedWatchList = {
                    id: state.watchlists[index].id,
                    value: state.watchlists[index].id,
                    label: state.watchlists[index].name,
                    markets: updatedMarkets
                };
            }
        }
    }
});

export const {
    addWatchList,
    editWatchList,
    deleteWatchList,
    arrangeWatchList,
    editWatchListMarket,
    setSelectedWatchList,
    setShouldReinstateWatchlist,
    addSubscription,
    removeSubscription
} = traderMarketSlice.actions;
export const selectCurrentWatchList = (state: RootState) => state.traderMarket.selectedWatchList;
export const selectTraderWatchLists = (state: RootState) => state.traderMarket.watchlists;
export const selectShouldReinstateWatchlist = (state: RootState) => state.traderMarket.shouldReinstateWatchlist;

export default traderMarketSlice.reducer;
