import { Fragment, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router';
import LiteLayout from './components/layout/lite/LiteLayout';
import IdleModal from './components/modal/IdleModal';
import { useAppDispatch, useAppSelector } from './state/hooks';
import { controlInitSubscriptions, selectCredentials } from './state/reducers/authSlice';
import { useDisclosure } from './utils/hooks/useDisclosure';
import { useIdle } from './utils/hooks/useIdle';

import { ExecutionType } from './compiled_proto/com/celertech/orderrouting/api/enums/ExecutionTypeProto';
import ExportCSV from './components/common/ExportCSV';
import Loader from './components/common/Loader';
import LiteSidebar from './components/layout/lite/LiteSidebar';
import LiteHistoryTable from './components/lite-modules/LiteHistoryTable';
import LiteSearchControls from './components/lite-modules/LiteSearchControls';
import { selectHistoricTrades } from './state/reducers/blotterSlice';
import { fetchUserMarkets } from './state/reducers/marketPairSlice';
import { selectGeneralSettings } from './state/reducers/settingSlice';

const xplorspotliteEnabled = window.config?.layout?.xplorspotlite?.enabled;

function LiteHistory() {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const settings = useAppSelector(selectGeneralSettings);
    const credentials = useAppSelector(selectCredentials);
    const historicTrades = useAppSelector(selectHistoricTrades);

    const idle = useIdle(settings.idleTimeout.value);
    const idleDisclosure = useDisclosure(false);

    const [CSVData, setCSVData] = useState<any[]>([]);
    const [isLoading, setIsLoading] = useState(false);
    const [executionType, setExecutiontype] = useState<any>({
        value: 'All',
        label: 'ALL'
    });

    const filteredTrades = useMemo(() => {
        return historicTrades
            ? historicTrades?.filter(
                  (trade) => executionType.value === 'All' || trade.executionType === ExecutionType[executionType.value]
              )
            : [];
    }, [historicTrades]);

    useEffect(() => {
        if (idle) idleDisclosure[1].open();
    }, [idle]);

    useEffect(() => {
        if (!credentials) {
            navigate('/login');
        } else {
            if (xplorspotliteEnabled) {
                // Fire initial requests
                dispatch(controlInitSubscriptions());
                dispatch(fetchUserMarkets());
            } else {
                navigate('/');
            }
        }
    }, []);

    return credentials ? (
        <Fragment>
            <LiteLayout>
                <div className="flex w-full h-full max-w-7xl text-neutral-200 p-0 sm:px-2 lg:divide-x-4 lg:divide-brand-primary">
                    <LiteSidebar />
                    <div className="flex flex-col flex-1 basis-0 overflow-auto h-full p-2 sm:p-6 pb-0 gap-3">
                        <div className="flex h-auto md:h-full w-full gap-2 sm:gap-6 flex-col-reverse md:flex-row">
                            <div className="flex flex-col h-full w-full gap-1 bg-brand-background-dark p-6">
                                <span className="text-neutral-200 font-bold text-base sm:text-lg">History</span>
                                <LiteSearchControls
                                    isLoading={isLoading}
                                    executionType={executionType}
                                    setIsLoading={setIsLoading}
                                    setExecutiontype={setExecutiontype}>
                                    {isLoading && (
                                        <div className="flex justify-center items-center flex-1">
                                            <Loader className="text-brand-primary" />
                                        </div>
                                    )}

                                    {!isLoading && !!filteredTrades?.length && (
                                        <div className="flex flex-col flex-1 p-2">
                                            <div className="flex items-center gap-2 mb-2 text-neutral-200">
                                                <div className="text-sm font-bold">Search Results</div>
                                                <ExportCSV csvData={CSVData} />
                                            </div>
                                            <div className="flex-1 basis-0 overflow-hidden">
                                                <LiteHistoryTable
                                                    historicTrades={filteredTrades || []}
                                                    setCSVData={setCSVData}
                                                />
                                            </div>
                                            <div className="text-sm font-bold text-neutral-200 mt-2">
                                                Rows returned: {CSVData.length}, out of a maximum of 200
                                            </div>
                                        </div>
                                    )}

                                    {!isLoading && (
                                        <>
                                            {!historicTrades && (
                                                <div className="trade-historic-search-empty p-2 flex justify-center items-center text-center flex-1 text-neutral-400 text-sm font-bold italic">
                                                    <div>Fill In The Above Criteria</div>
                                                </div>
                                            )}
                                            {historicTrades?.length === 0 && (
                                                <div className="trade-historic-search-empty p-2 flex justify-center items-center text-center flex-1 text-neutral-400 text-sm font-bold italic">
                                                    <div>No Orders Found. Please Try Another Search Criteria</div>
                                                </div>
                                            )}
                                        </>
                                    )}
                                </LiteSearchControls>
                            </div>
                            {/* <LiteMarkets /> */}
                        </div>
                    </div>
                </div>
                <IdleModal opened={idleDisclosure[0]} handlers={idleDisclosure[1]} />
            </LiteLayout>
        </Fragment>
    ) : null;
}

export default LiteHistory;
