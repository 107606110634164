import { convertedQtyFormatterConfig } from '@/config/config';
import { mergeRefs } from '@/utils/common';
import BigNumber from 'bignumber.js';
import cn from 'classnames';
import { ComponentProps, forwardRef, useEffect, useRef } from 'react';
import { useLocale, useNumberField } from 'react-aria';
import { useFormContext } from 'react-hook-form';
import { useNumberFieldState } from 'react-stately';

interface TickerSlippageInputProps extends ComponentProps<'input'> {
    name: string;
    label?: string;
    min?: string | number;
    step?: number;
    className?: any;
    defaultSlippage?: string;
}

const TickerSlippageInput = forwardRef<HTMLInputElement, TickerSlippageInputProps>((props, ref) => {
    const { name, step = 1, disabled = false, className, label, defaultSlippage } = props;
    const { getFieldState, getValues, formState, setValue, watch } = useFormContext();
    const fieldState = getFieldState(name, formState);

    const inputRef = useRef(null);
    const { locale } = useLocale();

    const state = useNumberFieldState({ locale, formatOptions: convertedQtyFormatterConfig });
    const { labelProps, groupProps, inputProps } = useNumberField({ label: label || name }, state, inputRef);

    const value = watch(name);

    useEffect(() => {
        if (value !== defaultSlippage && value !== state.numberValue && !isNaN(state.numberValue)) {
            setValue(name, state.numberValue, { shouldValidate: true, shouldDirty: true });
        } else if (isNaN(state.numberValue)) {
            setValue(name, 0);
        }
    }, [state.numberValue]);

    useEffect(() => {
        if (value !== defaultSlippage && value !== state.numberValue) {
            state.setNumberValue(value);
        }
    }, [value]);

    return (
        <div {...groupProps} className="relative flex flex-2">
            <label {...labelProps} className="block text-sm font-medium text-neutral-200">
                {label}
            </label>
            <input
                {...inputProps}
                ref={mergeRefs(inputRef, ref)}
                value={state.numberValue === 0 ? defaultSlippage : state.inputValue}
                autoComplete="off"
                className={cn(
                    'appearance-none block w-full px-2 py-1 bg-brand-background-dark border border-neutral-700 text-sm placeholder-neutral-500 focus:outline-none',
                    { 'text-neutral-400 cursor-not-allowed': disabled },
                    { 'text-neutral-200': !className && !disabled },
                    className
                )}
                placeholder="Slippage (pips)"
                disabled={disabled}
            />
            <div className="absolute right-1 flex flex-col h-full justify-center text-[8px] text-neutral-200 w-5">
                <button
                    type="button"
                    className={cn('-mb-0.5 px-0', {
                        'text-neutral-400 cursor-not-allowed': disabled,
                        'hover:text-brand-primary-light': !disabled
                    })}
                    onClick={() => {
                        if (!disabled) {
                            const prevValue = isNaN(getValues(name)) ? 0 : getValues(name);
                            const nextValue = BigNumber(prevValue).plus(step).toNumber();
                            setValue(name, nextValue, { shouldValidate: true });
                        }
                    }}>
                    &#x25B2;
                </button>
                <button
                    type="button"
                    className={cn('-mt-0.5 px-0', {
                        'text-neutral-400 cursor-not-allowed': disabled,
                        'hover:text-brand-primary-light': !disabled
                    })}
                    onClick={() => {
                        if (!disabled) {
                            const prevValue = isNaN(getValues(name)) ? 0 : getValues(name);
                            const nextValue = BigNumber(prevValue).minus(step).toNumber();
                            if (nextValue <= 0) setValue(name, 0, { shouldValidate: true });
                            else setValue(name, nextValue, { shouldValidate: true });
                        }
                    }}>
                    &#x25BC;
                </button>
            </div>
            {fieldState.error ? <span className="text-brand-red italic">{fieldState.error?.message}</span> : null}
        </div>
    );
});

TickerSlippageInput.displayName = 'TickerSlippageInput';

export default TickerSlippageInput;
