import { useClickOutside } from '@/utils/hooks/useClickOutside';
import cn from 'classnames';
import { Children, ReactElement, ReactNode, cloneElement, useMemo } from 'react';
import { Portal } from './Portal';

type ContextMenuProps = {
    x: number;
    y: number;
    visible: boolean;
    onClose: () => void;
    className?: string;
    offset?: { x: number; y: number };
    children: ReactElement | ReactElement[] | any;
};

const ContextMenu = ({ x, y, visible, onClose, className, offset = { x: 0, y: 0 }, children }: ContextMenuProps) => {
    const ref = useClickOutside(() => onClose());
    const documentHeight = document.body.scrollHeight;
    const documentWidth = document.body.scrollWidth;
    const showBottom = documentHeight - y > 120;
    const showLeft = documentWidth - x > 190;

    const left = useMemo(() => {
        if (showLeft) {
            return x + offset.x;
        } else {
            return x - 190 - offset.x;
        }
    }, [x, offset.x, showLeft]);

    const top = useMemo(() => {
        if (showBottom) {
            return y + offset.y;
        } else {
            return y - 120 - offset.y;
        }
    }, [y, offset.y, showLeft]);

    return (
        <Portal>
            <div ref={ref}>
                {visible && (
                    <div
                        style={{ left, top }}
                        className={cn('fixed z-50 border border-neutral-700 divide-y divide-white/10', {
                            ['w-48 py-1 bg-brand-background-dark rounded-md']: !className,
                            ['w-auto bg-brand-background']: className
                        })}
                        onContextMenu={onClose}>
                        {children && Children.map(children, (child) => child && cloneElement(child, { onClose }))}
                    </div>
                )}
            </div>
        </Portal>
    );
};

export default ContextMenu;

type MenuItem = {
    onClick?: () => void;
    onClose?: () => void;
    mode?: 'primary' | 'secondary';
    disabled?: boolean;
    className?: string;
    children: ReactNode;
};

export const ContextMenuItem = ({
    onClick,
    onClose,
    disabled = false,
    className,
    mode = 'primary',
    children
}: MenuItem) => {
    const handleClick = () => {
        if (onClick && !disabled) onClick();
        if (onClose) onClose();
    };
    return (
        <div
            className={cn(
                'flex gap-3 items-center text-sm rounded-sm select-none py-2 pl-3 pr-4',
                {
                    ['text-neutral-400 cursor-not-allowed']: disabled,
                    ['text-neutral-200 hover:bg-brand-primary cursor-pointer']: !disabled && mode === 'primary',
                    ['text-neutral-200 hover:bg-brand-red cursor-pointer']: !disabled && mode === 'secondary'
                },
                className
            )}
            onClick={handleClick}>
            {children}
        </div>
    );
};
