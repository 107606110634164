import { convertedQtyFormatterConfig } from '@/config/config';
import { mergeRefs, parseShortFormNumber } from '@/utils/common';
import BigNumber from 'bignumber.js';
import cn from 'classnames';
import { ComponentProps, forwardRef, useEffect, useRef } from 'react';
import { useLocale, useNumberField } from 'react-aria';
import { useFormContext } from 'react-hook-form';
import { useNumberFieldState } from 'react-stately';

interface Props extends ComponentProps<'input'> {
    min?: number;
    step?: number;
    label?: string;
    className?: string;
    defaultValue?: string | number;
}

const NumberInput = forwardRef<HTMLInputElement, Props>((props, ref: any) => {
    const { name = '', min = 0, step = 1, label, disabled, defaultValue, className } = props;
    const { getValues, setValue, resetField, watch } = useFormContext();

    const initValue = +(defaultValue || 0);

    const inputRef = useRef(null);
    const { locale } = useLocale();

    const state = useNumberFieldState({ locale, formatOptions: convertedQtyFormatterConfig });
    const { groupProps, inputProps } = useNumberField({ label: label || name }, state, inputRef);

    const value = watch(name);

    useEffect(() => {
        if (value !== state.numberValue && !isNaN(state.numberValue)) {
            setValue(name, state.numberValue, { shouldValidate: true, shouldDirty: true });
        } else if (isNaN(state.numberValue)) {
            setValue(name, null);
        }
    }, [state.numberValue]);

    useEffect(() => {
        if (value !== state.numberValue) {
            const nextValue = BigNumber(value);
            const isDirty = !nextValue.isEqualTo(initValue);
            if (!isDirty) resetField(name);
            state.setNumberValue(value);
        }
    }, [value]);

    // useEffect(() => {
    //     if (defaultValue) setValue(name, defaultValue, { shouldValidate: true, shouldDirty: true });
    // }, [defaultValue]);

    return (
        <div {...groupProps} className="relative flex">
            <input
                {...inputProps}
                ref={mergeRefs(inputRef, ref)}
                value={state.inputValue}
                placeholder={props.placeholder}
                onKeyDown={(e) => {
                    if (step >= 100_000 && ['k', 'm'].includes(e.key)) {
                        e.preventDefault();
                        const val = parseShortFormNumber(state.numberValue, e.key);
                        if (!isNaN(val)) {
                            setValue(name, val, { shouldValidate: true, shouldDirty: true });
                            state.commit();
                        }
                    }
                }}
                autoComplete="off"
                className={cn(
                    'appearance-none block w-full px-3 py-2 bg-brand-background-dark border border-neutral-700 rounded-md shadow-sm text-sm placeholder-neutral-500 focus:outline-none focus:ring-brand-primary focus:border-brand-primary',
                    { 'text-neutral-400 cursor-not-allowed': disabled },
                    { 'text-neutral-200': !className },
                    className
                )}
                disabled={disabled}
            />
            <div className="absolute right-2 flex flex-col h-full justify-center text-xs text-neutral-200 w-5">
                <button
                    type="button"
                    className={cn('px-0', {
                        'text-neutral-400 cursor-not-allowed': disabled,
                        'hover:text-brand-primary-light': !disabled
                    })}
                    onClick={() => {
                        if (!disabled) {
                            const prevValue = +(getValues(name) || 0);
                            const nextValue = BigNumber(prevValue).plus(step);
                            const isDirty = !nextValue.isEqualTo(initValue);
                            if (!isDirty) resetField(name);
                            setValue(name, nextValue.toNumber(), { shouldValidate: true, shouldDirty: isDirty });
                        }
                    }}>
                    &#x25B2;
                </button>
                <button
                    type="button"
                    className={cn('px-0', {
                        'text-neutral-400 cursor-not-allowed': disabled,
                        'hover:text-brand-primary-light': !disabled
                    })}
                    onClick={() => {
                        if (!disabled) {
                            const prevValue = +(getValues(name) || 0);
                            const nextValue = BigNumber(prevValue).minus(step);
                            if (nextValue.isLessThanOrEqualTo(min)) {
                                const isDirty = min !== initValue;
                                if (!isDirty) resetField(name);
                                setValue(name, min, { shouldValidate: true, shouldDirty: isDirty });
                            } else {
                                const isDirty = !nextValue.isEqualTo(initValue);
                                if (!isDirty) resetField(name);
                                setValue(name, nextValue.toNumber(), { shouldValidate: true, shouldDirty: isDirty });
                            }
                        }
                    }}>
                    &#x25BC;
                </button>
            </div>
        </div>
    );
});

NumberInput.displayName = 'NumberInput';

export default NumberInput;
