import { TimeInForceType } from '@/compiled_proto/com/celertech/positionmanager/api/enums/TimeInForceTypeProto';
import InputController from '@/components/inputs/InputController';
import Select from '@/components/inputs/Select';
import { showLayoutSwitch } from '@/utils/route';
import { Tab } from '@headlessui/react';

export const tradingLayoutOptions = [
    { label: 'XplorSpot (Advanced)', value: 'xplorspot' },
    { label: 'XplorSpotLite (Simplified)', value: 'xplorspotlite' }
    // { label: 'XplorTrader', value: 'xplortrader' }
];

export const twoClickTradingOptions = [
    { label: 'Enabled', value: true },
    { label: 'Disabled', value: false }
];

export const shortSellPreventionOptions = [
    { label: 'Disabled', value: false },
    { label: 'Enabled', value: true }
];

export const defaultMarketOrderDurationOptions: { label: keyof typeof TimeInForceType; value: TimeInForceType }[] = [
    { label: 'IOC', value: TimeInForceType.IOC },
    { label: 'FOK', value: TimeInForceType.FOK }
];

export const defaultLimitOrderDurationOptions: { label: keyof typeof TimeInForceType; value: TimeInForceType }[] = [
    { label: 'DAY', value: TimeInForceType.DAY },
    { label: 'GTC', value: TimeInForceType.GTC },
    { label: 'IOC', value: TimeInForceType.IOC },
    { label: 'FOK', value: TimeInForceType.FOK }
];

export const defaultStopOrderDurationOptions: { label: keyof typeof TimeInForceType; value: TimeInForceType }[] = [
    { label: 'DAY', value: TimeInForceType.DAY },
    { label: 'GTC', value: TimeInForceType.GTC }
];

const TradingTab = () => {
    return (
        <Tab.Panel className="p-2 py-3 sm:p-4 h-auto lg:h-[400px]">
            <div className="space-y-4">
                {showLayoutSwitch && !location.pathname.includes('/trader') && (
                    <div className="space-y-3 sm:space-y-2">
                        <InputController name="trading.tradingLayout" label="Trading Layout" required>
                            <Select options={tradingLayoutOptions} />
                        </InputController>
                        <div className="space-y-3 sm:space-y-1">
                            <div className="text-xs text-neutral-400">
                                Set a default layout for the trading interface, this can be changed at any time
                            </div>
                            <div className="text-xs text-neutral-400">
                                Changes will be applied the next time you login
                            </div>
                        </div>
                    </div>
                )}
                <div className="space-y-3 sm:space-y-2">
                    <InputController name="trading.twoClickTrading" label="2-Click Trading" required>
                        <Select options={twoClickTradingOptions} />
                    </InputController>
                    <div className="space-y-3 sm:space-y-1">
                        <div className="text-xs text-neutral-400">
                            When enabled, order placements will require additional confirmation (recommended)
                        </div>
                        <div className="text-xs text-neutral-400">
                            When disabled, orders are placed directly with a single click
                        </div>
                    </div>
                </div>
                {location.pathname.includes('/lite') && (
                    <div className="space-y-3 sm:space-y-2">
                        <InputController name="trading.shortSellPrevention" label="Short Sell Prevention" required>
                            <Select options={shortSellPreventionOptions} />
                        </InputController>
                        <div className="space-y-3 sm:space-y-1">
                            <div className="text-xs text-neutral-400">
                                When Enabled, IOC and FOK orders will only be accepted if the current balance in the
                                asset/fiat that is sold, is higher or equal to the amount requested to be sold. Any
                                pending orders are not taken into account
                            </div>
                        </div>
                    </div>
                )}
                <div className="space-y-3 sm:space-y-2">
                    <InputController
                        name="trading.defaultMarketOrderDuration"
                        label="Default Market Order Duration"
                        required>
                        <Select options={defaultMarketOrderDurationOptions} />
                    </InputController>
                    <div className="space-y-3 sm:space-y-1">
                        <div className="text-xs text-neutral-400">
                            The selected order duration will be the default duration for Market orders
                        </div>
                    </div>
                    <InputController
                        name="trading.defaultLimitOrderDuration"
                        label="Default Limit Order Duration"
                        required>
                        <Select options={defaultLimitOrderDurationOptions} />
                    </InputController>
                    <div className="space-y-3 sm:space-y-1">
                        <div className="text-xs text-neutral-400">
                            The selected order duration will be the default duration for Limit and OCO orders in the
                            Order Module
                        </div>
                    </div>
                    <InputController
                        name="trading.defaultStopOrderDuration"
                        label="Default Stop Order Duration"
                        required>
                        <Select options={defaultStopOrderDurationOptions} />
                    </InputController>
                    <div className="space-y-3 sm:space-y-1">
                        <div className="text-xs text-neutral-400">
                            The selected order duration will be the default duration for Stop and Stop Limit orders
                        </div>
                    </div>
                </div>
            </div>
        </Tab.Panel>
    );
};
export default TradingTab;
