import BigNumber from 'bignumber.js';
import { MutableRefObject, RefCallback } from 'react';

export const arrayRange = (start: number, stop: number, step: number) =>
    Array.from({ length: (stop - start) / step + 1 }, (value, index) => start + index * step);

type MutableRefList<T> = Array<RefCallback<T> | MutableRefObject<T> | undefined | null>;

export function mergeRefs<T>(...refs: MutableRefList<T>): RefCallback<T> {
    return (val: T) => {
        setRef(val, ...refs);
    };
}

export function setRef<T>(val: T, ...refs: MutableRefList<T>): void {
    refs.forEach((ref) => {
        if (typeof ref === 'function') {
            ref(val);
        } else if (ref != null) {
            ref.current = val;
        }
    });
}

export function parseShortFormNumber(numberValue, unit) {
    const units = { k: 1_000, m: 1_000_000 };
    if (units[unit]) return BigNumber(numberValue).multipliedBy(units[unit]).toNumber();
    return numberValue;
}
