import { portalURL } from '@/helpers/environmentHelper';
import { useAppDispatch, useAppSelector } from '@/state/hooks';
import {
    selectCredentials,
    selectCurrentAccount,
    selectCurrentAccountName,
    selectCurrentEntityName,
    setCurrentAccount,
    setCurrentAccountName,
    setCurrentEntityName
} from '@/state/reducers/authSlice';
import {
    openNotifications,
    selectNotificationsOpened,
    setNotificationsVisited
} from '@/state/reducers/notificationSlice';
import { UseDisclosureReturn } from '@/utils/hooks/useDisclosure';
import useXplorPortal from '@/utils/hooks/useXplorPortal';
import { navigateAccordingToAccountType } from '@/utils/navigation';
import { useSso } from '@/utils/providers/SSOProvider';
import { showLayoutSwitch } from '@/utils/route';
import { Menu, Transition } from '@headlessui/react';
import cn from 'classnames';
import { ButtonHTMLAttributes, Fragment, ReactNode, useMemo } from 'react';
import { IconType } from 'react-icons/lib';
import {
    MdApps,
    MdCandlestickChart,
    MdExpandMore,
    MdOfflineBolt,
    MdOutlineAccountBalance,
    MdOutlineAccountBalanceWallet,
    MdOutlineAccountBox,
    MdOutlineAccountCircle,
    MdOutlineAdminPanelSettings,
    MdOutlineLogout,
    MdOutlineSettings,
    MdSwapHoriz,
    MdSwitchAccount
} from 'react-icons/md';
import { useNavigate } from 'react-router-dom';
import { DropDown } from '../../common/Dropdown';
import NotificationButton from '../../common/NotificationButton';
import { onLogout, onUserChange } from '../Navbar';

interface LiteDesktopNavbarProps {
    depositDisclosure: UseDisclosureReturn;
    withdrawalDisclosure: UseDisclosureReturn;
    internalTransferDisclosure: UseDisclosureReturn;
    settingsDisclosure: UseDisclosureReturn;
}

const LiteDesktopNavbar = ({
    depositDisclosure,
    withdrawalDisclosure,
    internalTransferDisclosure,
    settingsDisclosure
}: LiteDesktopNavbarProps) => {
    const dispatch = useAppDispatch();
    const credentials = useAppSelector(selectCredentials);
    const currentAccount = useAppSelector(selectCurrentAccount);
    const currentAccountName = useAppSelector(selectCurrentAccountName);
    const currentEntityName = useAppSelector(selectCurrentEntityName);
    const notificationsOpened = useAppSelector(selectNotificationsOpened);

    const { entities } = useSso();
    const navigate = useNavigate();
    const { depositEnabled, withdrawalEnabled, canRequestDeposit, canRequestWithdrawal, isSSOEnabled } = useSso();
    const { onXplorPortal } = useXplorPortal(credentials);

    const currentAccountType = useMemo(() => {
        const properties = credentials?.accounts.find((account) => account.code === currentAccount)?.properties || [];
        return properties.find((property) => property.key === 'TYPE')?.value || 'NOP';
    }, [credentials, currentAccount]);

    const convertedAccounts = useMemo(() => {
        return (
            credentials?.accounts?.map((account) => ({
                code: account.code,
                name: account.name,
                properties: account.properties,
                entityName: entities?.find(
                    (entity) =>
                        entity.nameMappings?.find((nameMapping) => nameMapping.source === 'CELER')?.name ===
                        account.name
                )?.entityName
            })) || []
        );
    }, [credentials, entities]);

    const gcexAccountName = useMemo(() => {
        if (entities && !currentEntityName) {
            return (
                entities.find(
                    (entity) =>
                        entity.nameMappings?.find((nameMapping) => nameMapping.source === 'CELER')?.name ===
                        currentAccountName
                )?.entityName || currentAccountName
            );
        } else return currentAccountName;
    }, [entities, currentAccountName, currentEntityName]);

    return (
        <div className="hidden lg:flex h-full flex-row items-center gap-3 p-2">
            <NotificationButton
                size="w-6 h-6"
                isActive={notificationsOpened}
                onClick={() => {
                    dispatch(setNotificationsVisited(true));
                    dispatch(openNotifications());
                }}
                hasBorders={false}
            />
            <DropDown
                label="Switch Accounts"
                button={<MenuButton label={currentEntityName || gcexAccountName} Icon={MdOutlineAccountBox} />}>
                {convertedAccounts.map((account) => {
                    return (
                        <MenuItemButton
                            key={account.code}
                            label={account.entityName || account.name}
                            Icon={MdSwitchAccount}
                            onClick={() => {
                                dispatch(setCurrentAccount(account.code));
                                dispatch(setCurrentAccountName(account.name));
                                dispatch(setCurrentEntityName(account.entityName));
                                onUserChange(credentials, dispatch);
                                navigateAccordingToAccountType(account, navigate);
                            }}
                        />
                    );
                })}
            </DropDown>
            {showLayoutSwitch && !location.pathname.includes('/trader') && (
                <LiteMenu button={<LiteMenuButton Icon={MdApps} />}>
                    <MenuItemButton
                        label="XplorSpot"
                        Icon={MdCandlestickChart}
                        onClick={() => navigate('/')}
                        disabled={currentAccountType !== 'NOP'}
                        disabledMessage="Margin accounts are not supported in XplorSpot"
                    />
                    <MenuItemButton
                        label="XplorSpotLite"
                        Icon={MdOfflineBolt}
                        onClick={() => navigate('/lite')}
                        disabled={currentAccountType !== 'NOP'}
                        disabledMessage="Margin accounts are not supported in XplorSpotLite"
                    />
                </LiteMenu>
            )}
            {/* <NotificationButton
                size="w-6 h-6"
                isActive={notificationsOpened}
                hasBorders={false}
                onClick={() => {
                    dispatch(setNotificationsVisited(true));
                    dispatch(openNotifications());
                }}
            /> */}
            <LiteMenu button={<LiteMenuButton Icon={MdOutlineAccountCircle} />}>
                {portalURL && (
                    <MenuItemButton
                        label="XplorPortal"
                        Icon={MdOutlineAdminPanelSettings}
                        onClick={() => onXplorPortal()}
                    />
                )}
                {depositEnabled && (
                    <MenuItemButton
                        label="Deposits"
                        Icon={MdOutlineAccountBalanceWallet}
                        onClick={depositDisclosure[1].toggle}
                        disabled={!isSSOEnabled || !canRequestDeposit}
                    />
                )}
                {withdrawalEnabled && (
                    <MenuItemButton
                        label="Withdrawals"
                        Icon={MdOutlineAccountBalance}
                        onClick={withdrawalDisclosure[1].toggle}
                        disabled={!isSSOEnabled || !canRequestWithdrawal}
                    />
                )}
                <MenuItemButton
                    label="Internal Transfer"
                    Icon={MdSwapHoriz}
                    onClick={internalTransferDisclosure[1].toggle}
                    disabled={!isSSOEnabled || !(canRequestDeposit || canRequestWithdrawal)}
                />
                <MenuItemButton label="Settings" Icon={MdOutlineSettings} onClick={settingsDisclosure[1].toggle} />
                <MenuItemButton
                    label="Log out"
                    Icon={MdOutlineLogout}
                    onClick={() => onLogout(credentials, dispatch)}
                />
            </LiteMenu>
        </div>
    );
};

export default LiteDesktopNavbar;

interface ILiteMenu {
    button: ReactNode;
    className?: string;
    position?: 'left' | 'right';
    children: ReactNode;
}

export const LiteMenu = ({ button, className, position = 'right', children }: ILiteMenu) => {
    return (
        <div className={cn(`flex z-20`, className)}>
            <Menu as="div" className="relative inline-block text-left rounded-md">
                {button}
                <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95">
                    <Menu.Items
                        className={cn(
                            'absolute mt-2 w-48 origin-top-right divide-y divide-white/10 rounded-md py-1 bg-brand-background-dark border border-neutral-700 overflow-hidden right-0',
                            {
                                [`${position}-0`]: true
                            }
                        )}>
                        {children}
                    </Menu.Items>
                </Transition>
            </Menu>
        </div>
    );
};

interface LiteMenuButtonProps {
    Icon: IconType;
}

const LiteMenuButton = (props: LiteMenuButtonProps) => {
    const { Icon } = props;

    return (
        <Menu.Button className="flex gap-2 text-sm items-center justify-between text-neutral-200 bg-brand-background-dark rounded-md">
            <Icon className="w-6 h-6" />
        </Menu.Button>
    );
};

interface MenuButtonProps {
    Icon: IconType;
    label?: string;
}

const MenuButton = (props: MenuButtonProps) => {
    const { Icon, label } = props;

    return (
        <Menu.Button className="flex w-56 gap-2 text-sm items-center px-2 p-1 justify-between text-neutral-200 bg-brand-background-dark rounded-md">
            <span>
                <Icon className="h-5 w-5" />
            </span>
            <div className="truncate whitespace-nowrap">{label}</div>
            <span className="ml-auto">
                <MdExpandMore className="h-5 w-5" />
            </span>
        </Menu.Button>
    );
};

interface MenuItemButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
    Icon: IconType;
    label: string;
    disabledMessage?: string;
}

const MenuItemButton = (props: MenuItemButtonProps) => {
    const { Icon, label, disabledMessage, ...restProps } = props;
    const { disabled } = restProps;
    return (
        <Menu.Item>
            <button
                title={disabled ? disabledMessage : ''}
                className={cn('group flex w-full gap-2 items-center p-2 text-sm bg-brand-background-dark', {
                    'text-neutral-400 cursor-not-allowed': disabled,
                    'text-neutral-200 hover:bg-brand-primary': !disabled
                })}
                {...restProps}>
                <span>
                    <Icon className="w-6 h-6" />
                </span>
                <div className="truncate whitespace-nowrap">{label}</div>
            </button>
        </Menu.Item>
    );
};
