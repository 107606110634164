import BigNumber from 'bignumber.js';
import { useNumberFormatter } from 'react-aria';
import instrumentConfig, { InstrumentConfig } from '../../config/instruments';
import { arrayRange } from '../common';

type InstrumentDirection = 'linear' | 'invert';

export type UseInstrumentReturn = {
    config: InstrumentConfig;
    pip_size?: number;
    price_decimals?: number;
    defaultSlippage?: string;
    ccy2_enabled: boolean;
    instrumentDirection: InstrumentDirection;
    formatPip: (price: number) => GetPipIndexesReturn;
    formatPrice: (price: number) => string;
    formatAvgPrice: (price: number) => string;
    formatSpread: (spread: BigNumber) => string;
    formatHighlights: (prev: number, curr: number, prevHighlights?: GetColorIndexesReturn) => GetColorIndexesReturn;
    roundPrice: (price: number) => number;
};

export const useInstrument = (instrument: string, invert?: string): UseInstrumentReturn => {
    let instrumentDirection: InstrumentDirection = 'linear';

    const linearConfig = instrumentConfig[instrument];
    const invertConfig = invert ? instrumentConfig[invert] : undefined;

    if (invertConfig) instrumentDirection = 'invert';

    const config = linearConfig || invertConfig;

    const price_decimals = config?.price_decimals || 0;
    const pip_size = config?.pip_size || 0;
    const slipDifference = price_decimals - pip_size;
    const ccy2_enabled = !!config?.ccy2_enabled;

    const defaultSlippage = getDefaultSlippage({ slipDifference });

    const spreadFormatter = useNumberFormatter({
        minimumFractionDigits: slipDifference,
        maximumFractionDigits: slipDifference
    });

    const priceFormatter = useNumberFormatter({
        minimumFractionDigits: price_decimals,
        maximumFractionDigits: typeof price_decimals === 'number' ? price_decimals : 15
        // to be removed when instrumentConfig has 100% coverage
        // maximumSignificantDigits: 9
    });

    const avgPriceFormatter = useNumberFormatter({
        minimumFractionDigits: price_decimals,
        maximumFractionDigits: typeof price_decimals === 'number' ? price_decimals + 3 : 15
        // to be removed when instrumentConfig has 100% coverage
        // maximumSignificantDigits: 9
    });

    const formatPip = (price: number) => getPipIndexes({ price, priceFormatter, config });
    const formatPrice = (price: number) => priceFormatter.format(price);
    const formatAvgPrice = (price: number) => avgPriceFormatter.format(price);
    const formatSpread = (spread: BigNumber) =>
        spreadFormatter.format(spread.dividedBy(Math.pow(10, -pip_size)).toNumber());
    const formatHighlights = (prev: number, curr: number, prevHighlights?: GetColorIndexesReturn) =>
        getColorIndexes({ prev, curr, prevHighlights, formatPrice });

    const roundPrice = (price: number) => +price.toFixed(typeof price_decimals === 'number' ? price_decimals : 15);

    return {
        config,
        pip_size,
        price_decimals,
        defaultSlippage,
        ccy2_enabled,
        instrumentDirection,
        formatPip,
        formatPrice,
        formatAvgPrice,
        formatSpread,
        formatHighlights,
        roundPrice
    };
};

// Functional use cases of formatter, TODO: Refactor other instances formatPrice with this function.
export const getInstrumentFormatter = (locale: string, instrument: string, invert?: string): UseInstrumentReturn => {
    let instrumentDirection: InstrumentDirection = 'linear';

    const linearConfig = instrumentConfig[instrument];
    const invertConfig = invert ? instrumentConfig[invert] : undefined;

    if (invertConfig) instrumentDirection = 'invert';

    const config = linearConfig || invertConfig;

    const price_decimals = config?.price_decimals || 0;
    const pip_size = config?.pip_size || 0;
    const slipDifference = Math.abs(price_decimals - pip_size);
    const ccy2_enabled = !!config?.ccy2_enabled;

    const defaultSlippage = getDefaultSlippage({ slipDifference });

    const spreadFormatter = Intl.NumberFormat(locale, {
        minimumFractionDigits: slipDifference,
        maximumFractionDigits: slipDifference
    });

    const priceFormatter = Intl.NumberFormat(locale, {
        minimumFractionDigits: price_decimals,
        maximumFractionDigits: typeof price_decimals === 'number' ? price_decimals : 15
        // to be removed when instrumentConfig has 100% coverage
        // maximumSignificantDigits: 9
    });

    const avgPriceFormatter = Intl.NumberFormat(locale, {
        minimumFractionDigits: price_decimals,
        maximumFractionDigits: typeof price_decimals === 'number' ? price_decimals + 3 : 15
        // to be removed when instrumentConfig has 100% coverage
        // maximumSignificantDigits: 9
    });

    const formatPip = (price: number) => getPipIndexes({ price, priceFormatter, config });
    const formatPrice = (price: number) => priceFormatter.format(price);
    const formatAvgPrice = (price: number) => avgPriceFormatter.format(price);
    const formatSpread = (spread: BigNumber) =>
        spreadFormatter.format(spread.dividedBy(Math.pow(10, -pip_size)).toNumber());
    const formatHighlights = (prev: number, curr: number, prevHighlights?: GetColorIndexesReturn) =>
        getColorIndexes({ prev, curr, prevHighlights, formatPrice });

    const roundPrice = (price: number) => +price.toFixed(typeof price_decimals === 'number' ? price_decimals : 15);

    return {
        config,
        pip_size,
        price_decimals,
        defaultSlippage,
        ccy2_enabled,
        instrumentDirection,
        formatPip,
        formatPrice,
        formatAvgPrice,
        formatSpread,
        formatHighlights,
        roundPrice
    };
};

export interface GetPipIndexesParams {
    price: number;
    priceFormatter: Intl.NumberFormat;
    config?: InstrumentConfig;
}

export interface GetPipIndexesReturn {
    price: string;
    pipIndexes: number[];
}

const getPipIndexes = ({ price, priceFormatter, config }: GetPipIndexesParams): GetPipIndexesReturn => {
    const formattedPrice = priceFormatter.format(price);
    const separator = priceFormatter.formatToParts(price)?.find((part) => part.type === 'decimal')?.value;
    const separatorIndex = formattedPrice.split('').findIndex((str) => str === separator);

    if (config) {
        if (config.pip_size === 0) {
            // 00. should be bolded
            const pipIndex = separatorIndex === -1 ? formattedPrice.length - 1 : separatorIndex - 1;
            return { price: formattedPrice, pipIndexes: arrayRange(pipIndex - 1, pipIndex, 1) };
        } else if (config.pip_size === 1) {
            // 0.0 should be bolded
            const pipIndex = separatorIndex + 1;
            return { price: formattedPrice, pipIndexes: arrayRange(separatorIndex - 2, pipIndex, 1) };
        } else if (config.pip_size > 1) {
            const pipIndex = separatorIndex + config.pip_size;
            return { price: formattedPrice, pipIndexes: arrayRange(pipIndex - 1, pipIndex, 1) };
        } else {
            return { price: formattedPrice, pipIndexes: [] };
        }
    } else {
        return { price: formattedPrice, pipIndexes: [] };
    }
};

export interface GetColorIndexesParams {
    prev: number;
    curr: number;
    prevHighlights?: GetColorIndexesReturn;
    formatPrice(price: number): string;
}

export interface GetColorIndexesReturn {
    color: string;
    colorIndexes: number[];
    status: 'stale' | 'price_increased' | 'price_decreased';
}

export function getColorIndexes({
    prev,
    curr,
    prevHighlights,
    formatPrice
}: GetColorIndexesParams): GetColorIndexesReturn {
    // Determine if there is a difference
    const noDifference = prev === curr;

    // Convert the numbers to strings so we can access individual digits
    const prevStr = formatPrice(prev);
    const currStr = formatPrice(curr);

    // Find the index of the first digit that's different between the two numbers
    const diffIndex = prevStr.split('').findIndex((digit, index) => digit !== currStr[index]);

    // Determine if the digit at that index increased or decreased
    const digitA = parseInt(prevStr[diffIndex]);
    const digitB = parseInt(currStr[diffIndex]);
    const isIncreased = digitA < digitB;

    // Determine which color to use
    const color = isIncreased ? 'text-brand-primary' : 'text-brand-red';
    const eventStatus = isIncreased ? 'price_increased' : 'price_decreased';

    return noDifference
        ? {
              color: prevHighlights?.color || 'text-neutral-200',
              colorIndexes: prevHighlights?.colorIndexes || [],
              status: 'stale'
          }
        : { color, colorIndexes: arrayRange(diffIndex, currStr.length, 1), status: eventStatus };
}

export interface GetDefaultSlippageParams {
    slipDifference: number;
}

export function getDefaultSlippage({ slipDifference }: GetDefaultSlippageParams): string {
    const slipDecimals = new Array(slipDifference).fill('0').join('');
    const decimalSeparator = getDecimalSeparator(navigator.language);
    const defaultSlippage = slipDifference ? `0${decimalSeparator}${slipDecimals}` : '0';

    return defaultSlippage;
}

function getDecimalSeparator(locale: string) {
    const numberWithDecimalSeparator = 1.1;
    return (
        Intl.NumberFormat(locale)
            .formatToParts(numberWithDecimalSeparator)
            .find((part) => part.type === 'decimal')?.value || '.'
    );
}
