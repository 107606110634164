import { useEffect, useRef } from 'react';

export function useDebouncedOneClickTrading<T = any>(setValue, wait: number) {
    const timeoutRef = useRef<any>(null);

    const clearTimeout = () => window.clearTimeout(timeoutRef.current);
    useEffect(() => clearTimeout, []);

    const debouncedSetValue = (newValue: T) => {
        clearTimeout();
        timeoutRef.current = window.setTimeout(() => {
            setValue(newValue);
        }, wait);
    };

    return debouncedSetValue;
}
