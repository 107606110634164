import { BlotterItem } from '@/model/blotters';
import { Tab } from '@headlessui/react';
import Divider from '../common/Divider';
import AmendOrderForm from '../form/AmendOrderForm';
import Modal, { ModalClose, ModalHeader, ModalProps, ModalTab, ModalTabList, ModalTitle } from './Modal';

interface AmendOcoOrderModalProps extends ModalProps {
    order: BlotterItem;
    linkedOrder: BlotterItem;
    onCancelOrder: (order) => void;
}

const AmendOcoOrderModal = (props: AmendOcoOrderModalProps) => {
    const { order, linkedOrder, handlers, onCancelOrder } = props;
    return (
        <Modal {...props} className="lg:h-auto" size="max-w-xl">
            <ModalHeader>
                <ModalTitle>Amend Order</ModalTitle>
                <ModalClose handlers={handlers} />
            </ModalHeader>
            <Divider />
            <div className="h-full flex flex-col lg:h-auto lg:block w-full text-neutral-200 mt-[1px]">
                <Tab.Group>
                    <ModalTabList>
                        <ModalTab>Current Order</ModalTab>
                        <ModalTab>Linked Order</ModalTab>
                    </ModalTabList>
                    <Tab.Panels className="flex flex-1 overflow-hidden">
                        <Tab.Panel className="flex-1 overflow-y-auto">
                            <AmendOrderForm order={order} handlers={handlers} onCancelOrder={onCancelOrder} />
                        </Tab.Panel>
                        <Tab.Panel className="flex-1 overflow-y-auto">
                            <AmendOrderForm order={linkedOrder} handlers={handlers} onCancelOrder={onCancelOrder} />
                        </Tab.Panel>
                    </Tab.Panels>
                </Tab.Group>
            </div>
        </Modal>
    );
};

export default AmendOcoOrderModal;
