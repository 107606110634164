import { convertedQtyFormatterConfig } from '@/config/config';
import { mergeRefs } from '@/utils/common';
import cn from 'classnames';
import { ComponentProps, forwardRef, useEffect, useRef } from 'react';
import { useLocale, useNumberField } from 'react-aria';
import { useNumberFieldState } from 'react-stately';

interface Props extends ComponentProps<'input'> {
    min?: number;
    step?: number;
    label?: string;
    className?: string;
    defaultValue?: string | number;
}

const NumberInputDisplay = forwardRef<HTMLInputElement, Props>((props, ref: any) => {
    const { name = '', label, value, disabled, className } = props;

    const inputRef = useRef(null);
    const { locale } = useLocale();

    const state = useNumberFieldState({ locale, formatOptions: convertedQtyFormatterConfig });
    const { groupProps, inputProps } = useNumberField({ label: label || name }, state, inputRef);

    useEffect(() => {
        if (value) state.setNumberValue(+value);
    }, [value]);

    return (
        <div {...groupProps} className="relative flex">
            <input
                {...inputProps}
                ref={mergeRefs(inputRef, ref)}
                value={state.inputValue}
                placeholder={props.placeholder}
                autoComplete="off"
                className={cn(
                    'appearance-none block w-full px-3 py-2 bg-brand-background-dark border border-neutral-700 rounded-md shadow-sm text-sm placeholder-neutral-500 focus:outline-none focus:ring-brand-primary focus:border-brand-primary',
                    { 'text-neutral-400 cursor-not-allowed': disabled },
                    { 'text-neutral-200': !className },
                    className
                )}
                disabled={disabled}
            />
            <div className="absolute right-2 flex flex-col h-full justify-center text-xs text-neutral-200 w-5">
                <button
                    type="button"
                    className={cn('px-0', {
                        'text-neutral-400 cursor-not-allowed': disabled,
                        'hover:text-brand-primary-light': !disabled
                    })}>
                    &#x25B2;
                </button>
                <button
                    type="button"
                    className={cn('px-0', {
                        'text-neutral-400 cursor-not-allowed': disabled,
                        'hover:text-brand-primary-light': !disabled
                    })}>
                    &#x25BC;
                </button>
            </div>
        </div>
    );
});

NumberInputDisplay.displayName = 'NumberInputDisplay';

export default NumberInputDisplay;
