import { convertedQtyFormatterConfig } from '@/config/config';
import { mergeRefs } from '@/utils/common';
import BigNumber from 'bignumber.js';
import cn from 'classnames';
import { ComponentProps, forwardRef, useEffect, useRef } from 'react';
import { useLocale, useNumberField } from 'react-aria';
import { useFormContext } from 'react-hook-form';
import { useNumberFieldState } from 'react-stately';

interface Props extends ComponentProps<'input'> {
    step?: number;
    label?: string;
    className?: string;
    defaultValue?: string | number;
    defaultSlippage?: string;
}

const SlippageInput = forwardRef<HTMLInputElement, Props>((props, ref: any) => {
    const { name = '', step = 1, label, disabled, defaultValue, defaultSlippage, className } = props;
    const { getValues, setValue, watch } = useFormContext();

    const inputRef = useRef(null);
    const { locale } = useLocale();

    const state = useNumberFieldState({ locale, formatOptions: convertedQtyFormatterConfig });
    const { labelProps, groupProps, inputProps } = useNumberField({ label: label || name }, state, inputRef);

    const value = watch(name);

    useEffect(() => {
        if (value !== defaultSlippage && value !== state.numberValue && !isNaN(state.numberValue)) {
            setValue(name, state.numberValue, { shouldValidate: true, shouldDirty: true });
        } else if (isNaN(state.numberValue)) {
            setValue(name, 0);
        }
    }, [state.numberValue]);

    useEffect(() => {
        if (value !== defaultSlippage && value !== state.numberValue) {
            state.setNumberValue(value);
        }
    }, [value]);

    return (
        <div className="w-full gap-1 flex flex-col">
            <label {...labelProps} className="block text-sm font-medium text-neutral-200">
                {label}
            </label>
            <div {...groupProps} className="relative flex">
                <input
                    {...inputProps}
                    ref={mergeRefs(inputRef, ref)}
                    value={state.numberValue === 0 ? defaultSlippage : state.inputValue}
                    placeholder={props.placeholder}
                    autoComplete="off"
                    className={cn(
                        'appearance-none block w-full px-3 py-2 bg-brand-background-dark border border-neutral-700 rounded-md shadow-sm text-sm placeholder-neutral-500 focus:outline-none focus:ring-brand-primary focus:border-brand-primary',
                        { 'text-neutral-400 cursor-not-allowed': disabled },
                        { 'text-neutral-200': !className },
                        className
                    )}
                    disabled={disabled}
                />
                <div className="absolute right-2 flex flex-col h-full justify-center text-xs text-neutral-200 w-5">
                    <button
                        type="button"
                        className={cn('px-0', {
                            'text-neutral-400 cursor-not-allowed': disabled,
                            'hover:text-brand-primary-light': !disabled
                        })}
                        onClick={() => {
                            if (!disabled) {
                                const prevValue = isNaN(getValues(name)) ? 0 : getValues(name);
                                const nextValue = BigNumber(prevValue).plus(step).toNumber();
                                setValue(name, nextValue, { shouldValidate: true });
                            }
                        }}>
                        &#x25B2;
                    </button>
                    <button
                        type="button"
                        className={cn('px-0', {
                            'text-neutral-400 cursor-not-allowed': disabled,
                            'hover:text-brand-primary-light': !disabled
                        })}
                        onClick={() => {
                            if (!disabled) {
                                const prevValue = isNaN(getValues(name)) ? 0 : getValues(name);
                                const nextValue = BigNumber(prevValue).minus(step).toNumber();
                                if (nextValue <= 0) setValue(name, 0, { shouldValidate: true });
                                else setValue(name, nextValue, { shouldValidate: true });
                            }
                        }}>
                        &#x25BC;
                    </button>
                </div>
            </div>
        </div>
    );
});

SlippageInput.displayName = 'SlippageInput';

export default SlippageInput;
