import { OrderStatus } from '@/compiled_proto/com/celertech/orderrouting/api/enums/OrderStatusProto';
import { TimeInForceType } from '@/compiled_proto/com/celertech/orderrouting/api/enums/TimeInForceTypeProto';
import { OrderType } from '@/compiled_proto/com/celertech/positionmanager/api/enums/OrderTypeProto';
import ContextMenu, { ContextMenuItem } from '@/components/common/ContextMenu';
import AmendOcoOrderModal from '@/components/modal/AmendOcoOrderModal';
import AmendOrderModal from '@/components/modal/AmendOrderModal';
import DealHistoryModal from '@/components/modal/DealHistoryModal';
import OrderCancellationModal from '@/components/modal/OrderCancellationModal';
import VirtualizedTable from '@/components/table/VirtualizedTable';
import { defaultColumn, numberFormatterConfig } from '@/config/config';
import { orderColumns } from '@/helpers/table/orderBlotterTableHelper';
import { BlotterItem } from '@/model/blotters';
import { Modify } from '@/model/common';
import { useAppSelector } from '@/state/hooks';
import { selectCurrentAccount } from '@/state/reducers/authSlice';
import { selectBlotterItems } from '@/state/reducers/blotterSlice';
import { selectGeneralSettings } from '@/state/reducers/settingSlice';
import { formatDateTime, formatLocalDateTime } from '@/utils/format';
import useContextMenu from '@/utils/hooks/useContextMenu';
import { useDisclosure } from '@/utils/hooks/useDisclosure';
import { getInstrumentFormatter } from '@/utils/hooks/useInstrument';
import useScreenSizes from '@/utils/hooks/useScreenSizes';
import { useTrueDateFormatter } from '@/utils/hooks/useTrueDateFormatter';
import { translatePair } from '@/utils/symbolMapping';
import {
    ExpandedState,
    SortingState,
    getCoreRowModel,
    getExpandedRowModel,
    getFacetedMinMaxValues,
    getFacetedRowModel,
    getFacetedUniqueValues,
    getFilteredRowModel,
    getSortedRowModel,
    useReactTable
} from '@tanstack/react-table';
import cn from 'classnames';
import { Fragment, useEffect, useMemo, useState } from 'react';
import { useLocale, useNumberFormatter } from 'react-aria';
import { MdCancel, MdEdit, MdHistory } from 'react-icons/md';

export interface OrderBlotterRow
    extends Modify<
        BlotterItem,
        {
            strategy: string;
            parentStrategyID: string;
            created: string | 0;
            updated: string | 0;
            instrument: string;
            qty: string;
            price: string;
            slippage: string;
            lastPrice: string;
            avgPrice: string;
            stopPrice: string;
        }
    > {}

const onAction = (e: RowEvent, callback: (amendable: boolean) => void) => {
    const { original } = e;
    let amendable = false;
    if (original.orderId.includes('STRATEGYORDER')) {
        amendable = false;
    } else {
        const tif = TimeInForceType[original?.tif || ''] as unknown as TimeInForceType;
        const status = OrderStatus[original?.status || ''] as unknown as OrderStatus;
        const orderType = OrderType[original?.orderType || ''] as unknown as OrderType;
        // if duration is DAY/GTC && if order status is NEW/PART_FILLED

        amendable =
            [TimeInForceType.DAY, TimeInForceType.GTC].includes(tif) &&
            [OrderStatus.NEW, OrderStatus.PART_FILLED].includes(status) &&
            [OrderType.LIMIT, OrderType.STOP_MARKET, OrderType.STOP_LIMIT].includes(orderType);
    }

    callback(amendable);
};

type RowEvent = { event: MouseEvent; original: OrderBlotterRow };

export type PositionFilter = 'Show All' | 'Filled' | 'Open' | 'Cancelled';

const positionFilters: { label: PositionFilter; value: PositionFilter }[] = [
    { label: 'Show All', value: 'Show All' },
    // (Filled and Partially Filled)
    { label: 'Filled', value: 'Filled' },
    // (New and Partially Filled)
    { label: 'Open', value: 'Open' },
    // (Canceled)
    { label: 'Cancelled', value: 'Cancelled' }
];

interface OrderBlotterTableProps {
    setCSVData: React.Dispatch<React.SetStateAction<any[]>>;
}

const OrderBlotterTable = (props: OrderBlotterTableProps) => {
    const screen = useScreenSizes();

    const orders = useAppSelector(selectBlotterItems);

    const amendDisclosure = useDisclosure(false);
    const amendOcoDisclosure = useDisclosure(false);
    const historyDisclosure = useDisclosure(false);
    const cancelDisclosure = useDisclosure(false);

    const { contextMenuX, contextMenuY, contextMenuVisible, handleOpenContextMenu, handleCloseContextMenu } =
        useContextMenu();

    const [orderId, setOrderId] = useState<string | undefined>();
    const [linkedOrderId, setLinkedOrderId] = useState<string | undefined>();
    const [cancelOrder, setCancelOrder] = useState<BlotterItem | undefined>();
    const [isAmendable, setIsAmendable] = useState<boolean>(false);
    const [isAmendOco, setIsAmendOco] = useState<boolean>(false);
    const [positionFilter, setPositionFilter] = useState<PositionFilter>('Show All');

    const { order, linkedOrder, tableInstance } = useOpenPositionsTable({
        ...props,
        orderId,
        linkedOrderId,
        positionFilter,
        amendDisclosure,
        amendOcoDisclosure,
        handleOpenContextMenu
    });

    return (
        <Fragment>
            <div className="flex flex-col h-full gap-2">
                <div className="flex">
                    {positionFilters.map((filter, index) => {
                        return (
                            <button
                                key={filter.label}
                                className={cn('text-sm py-1 px-2 text-neutral-200 border', {
                                    'border-brand-primary bg-brand-primary': positionFilter === filter.value,
                                    'border-neutral-600 hover:bg-brand-primary-light hover:border-brand-primary-light':
                                        positionFilter !== filter.value,
                                    'border-r-0': index !== positionFilters.length - 1
                                })}
                                onClick={() => setPositionFilter(filter.value)}>
                                {filter.label}
                            </button>
                        );
                    })}
                </div>
                <div className="flex-1 basis-0 overflow-hidden">
                    <VirtualizedTable
                        tableInstance={tableInstance}
                        onCellContextMenu={(e: RowEvent) =>
                            onAction(e, (amendable) => {
                                if (e.original.orderId.includes('STRATEGYORDER')) return;
                                else {
                                    if (e.original.strategy === 'OCO') {
                                        const ocoOrders = orders.filter((order) => {
                                            const foundParentStrategy = order.metadata.find(
                                                ({ key }) => key === 'PARENT_STRATEGY_ID'
                                            );

                                            return (
                                                foundParentStrategy &&
                                                foundParentStrategy.value === e.original.parentStrategyID
                                            );
                                        });

                                        if (ocoOrders) {
                                            const linkedOrder = ocoOrders.filter(
                                                (order) => order.orderId !== e.original.orderId
                                            )[0];
                                            setIsAmendOco(true);
                                            setOrderId(e.original.orderId);
                                            setLinkedOrderId(linkedOrder.orderId);
                                            setIsAmendable(amendable);
                                            handleOpenContextMenu(e.event as any);
                                        }
                                    } else {
                                        setIsAmendOco(false);
                                        setOrderId(e.original.orderId);
                                        setIsAmendable(amendable);
                                        handleOpenContextMenu(e.event as any);
                                    }
                                }
                            })
                        }
                        onRowDoubleClicked={(e: RowEvent) =>
                            onAction(e, (amendable) => {
                                if (e.original.orderId.includes('STRATEGYORDER')) return;
                                else {
                                    if (e.original.strategy === 'OCO') {
                                        const ocoOrders = orders.filter((order) => {
                                            const foundParentStrategy = order.metadata.find(
                                                ({ key }) => key === 'PARENT_STRATEGY_ID'
                                            );

                                            return (
                                                foundParentStrategy &&
                                                foundParentStrategy.value === e.original.parentStrategyID
                                            );
                                        });

                                        if (ocoOrders) {
                                            const linkedOrder = ocoOrders.filter(
                                                (order) => order.orderId !== e.original.orderId
                                            )[0];
                                            setIsAmendOco(true);
                                            setOrderId(e.original.orderId);
                                            setLinkedOrderId(linkedOrder.orderId);
                                            setIsAmendable(amendable);
                                            amendOcoDisclosure[1].toggle();
                                        }
                                    } else {
                                        setIsAmendOco(false);
                                        setOrderId(e.original.orderId);
                                        setIsAmendable(amendable);
                                        amendDisclosure[1].toggle();
                                    }
                                }
                            })
                        }
                        onRowClicked={(e: RowEvent) => {
                            if (screen.mobile || screen.tablet) {
                                onAction(e, (amendable) => {
                                    if (e.original.orderId.includes('STRATEGYORDER')) return;
                                    else {
                                        if (e.original.strategy === 'OCO') {
                                            const ocoOrders = orders.filter((order) => {
                                                const foundParentStrategy = order.metadata.find(
                                                    ({ key }) => key === 'PARENT_STRATEGY_ID'
                                                );

                                                return (
                                                    foundParentStrategy &&
                                                    foundParentStrategy.value === e.original.parentStrategyID
                                                );
                                            });

                                            if (ocoOrders) {
                                                const linkedOrder = ocoOrders.filter(
                                                    (order) => order.orderId !== e.original.orderId
                                                )[0];
                                                setIsAmendOco(true);
                                                setOrderId(e.original.orderId);
                                                setLinkedOrderId(linkedOrder.orderId);
                                                setIsAmendable(amendable);
                                                handleOpenContextMenu(e.event as any);
                                            }
                                        } else {
                                            setIsAmendOco(false);
                                            setOrderId(e.original.orderId);
                                            setIsAmendable(amendable);
                                            handleOpenContextMenu(e.event as any);
                                        }
                                    }
                                });
                            }
                        }}
                        isLoadingTableContent={false}
                    />
                </div>
            </div>
            <ContextMenu
                x={contextMenuX}
                y={contextMenuY}
                visible={contextMenuVisible}
                onClose={handleCloseContextMenu}>
                <ContextMenuItem onClick={historyDisclosure[1].open}>
                    <MdHistory />
                    <span>View Order History</span>
                </ContextMenuItem>
                <ContextMenuItem
                    onClick={() => {
                        if (isAmendOco) {
                            amendOcoDisclosure[1].open();
                        } else {
                            amendDisclosure[1].open();
                        }
                    }}
                    disabled={!isAmendable}>
                    <MdEdit />
                    <span>Amend Order</span>
                </ContextMenuItem>
                <ContextMenuItem
                    mode="secondary"
                    onClick={() => {
                        const order = orders.find((order) => order.orderId === orderId);
                        setCancelOrder(order);
                        cancelDisclosure[1].open();
                    }}
                    disabled={!isAmendable}>
                    <MdCancel />
                    <span>Cancel Order</span>
                </ContextMenuItem>
            </ContextMenu>
            {order && (
                <>
                    <AmendOrderModal
                        order={order}
                        opened={amendDisclosure[0]}
                        handlers={amendDisclosure[1]}
                        onCancelOrder={(orderToCancel) => {
                            setCancelOrder(orderToCancel);
                            amendDisclosure[1].close();
                            cancelDisclosure[1].open();
                        }}
                    />
                    {linkedOrder && (
                        <AmendOcoOrderModal
                            order={order}
                            linkedOrder={linkedOrder}
                            opened={amendOcoDisclosure[0]}
                            handlers={amendOcoDisclosure[1]}
                            onCancelOrder={(orderToCancel) => {
                                setCancelOrder(orderToCancel);
                                amendOcoDisclosure[1].close();
                                cancelDisclosure[1].open();
                            }}
                        />
                    )}
                    <DealHistoryModal order={order} opened={historyDisclosure[0]} handlers={historyDisclosure[1]} />
                    {cancelOrder && (
                        <OrderCancellationModal
                            order={cancelOrder}
                            opened={cancelDisclosure[0]}
                            handlers={cancelDisclosure[1]}
                        />
                    )}
                </>
            )}
        </Fragment>
    );
};

export default OrderBlotterTable;

export const mapOrderBlotters = (
    order: BlotterItem,
    locale: string,
    formatDate: any,
    formatNumber: any,
    isUTC: boolean
): OrderBlotterRow => {
    const { formatPrice, formatAvgPrice } = getInstrumentFormatter(locale, order.pair);
    let strategy = '-';
    let parentStrategyID;
    const foundStrategy = order.metadata.find(({ key }) => key === 'ORIGINAL_STRATEGY_ORDER_TYPE');
    const foundParentStrategy = order.metadata.find(({ key }) => key === 'PARENT_STRATEGY_ID');

    if (foundStrategy && foundParentStrategy) {
        strategy = foundStrategy.value;
        parentStrategyID = foundParentStrategy.value;
    }

    return {
        ...order,
        strategy,
        parentStrategyID,
        created:
            order.created &&
            (isUTC ? formatDateTime(formatDate, order.created) : formatLocalDateTime(formatDate, order.created)),
        updated:
            order.updated &&
            (isUTC ? formatDateTime(formatDate, order.updated) : formatLocalDateTime(formatDate, order.updated)),
        instrument: translatePair(order.pair),
        qty: formatNumber.format(order.qty),
        price: formatPrice(order.price),
        slippage: formatNumber.format(+(order.slippage || 0)),
        lastPrice: formatPrice(order.lastPrice),
        avgPrice: formatAvgPrice(order.avgPrice),
        stopPrice: formatPrice(order.stopPrice)
    };
};

interface UseOrderBlotterTableProps extends OrderBlotterTableProps {
    orderId: string | undefined;
    linkedOrderId: string | undefined;
    positionFilter: PositionFilter;
    amendDisclosure: ReturnType<typeof useDisclosure>;
    amendOcoDisclosure: ReturnType<typeof useDisclosure>;
    handleOpenContextMenu: (row: any, x: number, y: number) => void;
}

const useOpenPositionsTable = (props: UseOrderBlotterTableProps) => {
    const { orderId, linkedOrderId, positionFilter, amendDisclosure, amendOcoDisclosure, setCSVData } = props;

    const { locale } = useLocale();
    const orders = useAppSelector(selectBlotterItems);
    const currentAccount = useAppSelector(selectCurrentAccount);
    const generalSettings = useAppSelector(selectGeneralSettings);

    const formatDate = useTrueDateFormatter();
    const formatNumber = useNumberFormatter(numberFormatterConfig);

    const [sorting, setSorting] = useState<SortingState>([{ id: 'updated', desc: true }]);

    // const [debouncedOrderBlotters, setDebouncedOrderBlotters] = useState<BlotterItem[]>([]);
    const [expandedRows, setExpandedRows] = useState<ExpandedState>({});

    const order = useMemo(
        () => orders.find((order) => order.orderId === orderId),
        [orderId, amendDisclosure[0], amendOcoDisclosure[0]]
    );
    const linkedOrder = useMemo(
        () => orders.find((order) => order.orderId === linkedOrderId),
        [linkedOrderId, amendDisclosure[0], amendOcoDisclosure[0]]
    );

    const isUTC = useMemo(() => generalSettings.timezone.value === 'UTC', [generalSettings]);
    const formattedOrders: any = useMemo(() => {
        const currentAccountOrders = orders.filter(
            (order) => order.account === currentAccount || order.account === currentAccount?.slice(0, 36)
        );
        const filteredOrders = currentAccountOrders.filter((order) => {
            if (positionFilter === 'Show All') return true;
            if (positionFilter === 'Filled') {
                return [OrderStatus.FILLED, OrderStatus.PART_FILLED].includes(OrderStatus[order.status]);
            }
            if (positionFilter === 'Open') {
                return [OrderStatus.NEW, OrderStatus.PART_FILLED].includes(OrderStatus[order.status]);
            }
            if (positionFilter === 'Cancelled') {
                // OrderStatus.REJECTED
                return [OrderStatus.CANCELED].includes(OrderStatus[order.status]);
            }
            return true;
        });

        const orderBlotters = filteredOrders.map((order) =>
            mapOrderBlotters(order, locale, formatDate, formatNumber, isUTC)
        );
        const groupedOrders = groupRowsByParentStrategyId(orderBlotters);

        return groupedOrders;
    }, [orders, locale, currentAccount, positionFilter, isUTC]);

    const columns = useMemo(() => orderColumns(generalSettings), [generalSettings]);

    const tableInstance = useReactTable({
        data: formattedOrders,
        columns,
        state: { sorting, expanded: expandedRows },
        defaultColumn,
        enableColumnResizing: true,
        columnResizeMode: 'onChange',
        globalFilterFn: 'includesString',
        onSortingChange: (sorting) => setSorting(sorting),
        onExpandedChange: setExpandedRows,
        getCoreRowModel: getCoreRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        getSortedRowModel: getSortedRowModel(),
        getFacetedRowModel: getFacetedRowModel(),
        getFacetedUniqueValues: getFacetedUniqueValues(),
        getFacetedMinMaxValues: getFacetedMinMaxValues(),
        getExpandedRowModel: getExpandedRowModel(),
        getSubRows: (row: any) => row.subRows,
        enableRowSelection: true
    });

    // const [debouncedTableInstance] = useDebouncedValue(tableInstance, 5000);

    useEffect(() => {
        setCSVData(formattedOrders);
    }, [formattedOrders]);

    useEffect(() => {
        tableInstance.toggleAllRowsExpanded();
    }, []);

    // useInterval(
    //     () => {
    //         setDebouncedOrderBlotters(store.getState().);
    //     },
    //     5000,
    //     true
    // );

    return { order, linkedOrder, tableInstance, formattedOrders };
};

const groupRowsByParentStrategyId = (rows: OrderBlotterRow[]) => {
    const groupedMap = new Map<string, any>();

    rows.forEach((item) => {
        const { parentStrategyID } = item;

        if (parentStrategyID) {
            if (groupedMap.has(parentStrategyID)) {
                const group = groupedMap.get(parentStrategyID)!;

                group.subRows.push(item);
            } else {
                groupedMap.set(parentStrategyID, {
                    ...item,
                    orderId: parentStrategyID,
                    status: '-',
                    orderType: '-',
                    qty: '-',
                    price: '-',
                    lastPrice: '-',
                    lastQty: '-',
                    avgPrice: '-',
                    stopPrice: '-',
                    cumQty: '-',
                    leavesQty: '-',
                    executionType: '-',
                    subRows: [item]
                });
            }
        } else {
            groupedMap.set(item.orderId, item);
        }
    });
    return Array.from(groupedMap.values());
};
