import { BlotterItem } from '@/model/blotters';
import Divider from '../common/Divider';
import AmendOrderForm from '../form/AmendOrderForm';
import Modal, { ModalClose, ModalHeader, ModalProps, ModalTitle } from './Modal';

interface AmendOrderModalProps extends ModalProps {
    order: BlotterItem;
    onCancelOrder: (order) => void;
}

const AmendOrderModal = (props: AmendOrderModalProps) => {
    const { order, handlers, onCancelOrder } = props;

    return (
        <Modal {...props} className="lg:h-auto" size="max-w-xl">
            <ModalHeader>
                <ModalTitle>Amend Order</ModalTitle>
                <ModalClose handlers={handlers} />
            </ModalHeader>
            <Divider />
            <AmendOrderForm order={order} handlers={handlers} onCancelOrder={onCancelOrder} />
        </Modal>
    );
};

export default AmendOrderModal;
