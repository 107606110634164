export interface NetDaniaConfig {
    provider: 'netdania_rtc' | 'cryptoxchange';
}

type NetDaniaConfigProps = Record<string, NetDaniaConfig>;

const netdaniaConfig: NetDaniaConfigProps = {
    ADAUSD: { provider: 'netdania_rtc' },
    ADAZAR: { provider: 'netdania_rtc' },
    AUDCAD: { provider: 'netdania_rtc' },
    AUDCHF: { provider: 'netdania_rtc' },
    AUDJPY: { provider: 'netdania_rtc' },
    AUDNZD: { provider: 'netdania_rtc' },
    AUDUSD: { provider: 'netdania_rtc' },
    BCHAUD: { provider: 'netdania_rtc' },
    BCHEUR: { provider: 'netdania_rtc' },
    BCHGBP: { provider: 'netdania_rtc' },
    BCHJPY: { provider: 'netdania_rtc' },
    BCHUSD: { provider: 'netdania_rtc' },
    BCHZAR: { provider: 'netdania_rtc' },
    BTCAUD: { provider: 'netdania_rtc' },
    BTCBCH: { provider: 'netdania_rtc' },
    BTCEUR: { provider: 'netdania_rtc' },
    BTCGBP: { provider: 'netdania_rtc' },
    BTCJPY: { provider: 'netdania_rtc' },
    BTCSGD: { provider: 'netdania_rtc' },
    BTCUSD: { provider: 'netdania_rtc' },
    BTCZAR: { provider: 'netdania_rtc' },
    CADCHF: { provider: 'netdania_rtc' },
    CADJPY: { provider: 'netdania_rtc' },
    CHFJPY: { provider: 'netdania_rtc' },
    CNHJPY: { provider: 'netdania_rtc' },
    DOGUSD: { provider: 'netdania_rtc' },
    DOTUSD: { provider: 'netdania_rtc' },
    EOSAUD: { provider: 'netdania_rtc' },
    EOSBTC: { provider: 'netdania_rtc' },
    EOSEUR: { provider: 'netdania_rtc' },
    EOSGBP: { provider: 'netdania_rtc' },
    EOSUSD: { provider: 'netdania_rtc' },
    ETHAUD: { provider: 'netdania_rtc' },
    ETHBCH: { provider: 'netdania_rtc' },
    ETHEUR: { provider: 'netdania_rtc' },
    ETHGBP: { provider: 'netdania_rtc' },
    ETHJPY: { provider: 'netdania_rtc' },
    ETHUSD: { provider: 'netdania_rtc' },
    ETHZAR: { provider: 'netdania_rtc' },
    EURAUD: { provider: 'netdania_rtc' },
    EURCAD: { provider: 'netdania_rtc' },
    EURCHF: { provider: 'netdania_rtc' },
    EURCZK: { provider: 'netdania_rtc' },
    EURDKK: { provider: 'netdania_rtc' },
    EURGBP: { provider: 'netdania_rtc' },
    EURHKD: { provider: 'netdania_rtc' },
    EURHUF: { provider: 'netdania_rtc' },
    EURJPY: { provider: 'netdania_rtc' },
    EURMXN: { provider: 'netdania_rtc' },
    EURNOK: { provider: 'netdania_rtc' },
    EURNZD: { provider: 'netdania_rtc' },
    EURPLN: { provider: 'netdania_rtc' },
    EURSEK: { provider: 'netdania_rtc' },
    EURSGD: { provider: 'netdania_rtc' },
    EURTRY: { provider: 'netdania_rtc' },
    EURUSD: { provider: 'netdania_rtc' },
    EURZAR: { provider: 'netdania_rtc' },
    GBPAUD: { provider: 'netdania_rtc' },
    GBPCAD: { provider: 'netdania_rtc' },
    GBPCHF: { provider: 'netdania_rtc' },
    GBPCZK: { provider: 'netdania_rtc' },
    GBPDKK: { provider: 'netdania_rtc' },
    GBPHKD: { provider: 'netdania_rtc' },
    GBPHUF: { provider: 'netdania_rtc' },
    GBPJPY: { provider: 'netdania_rtc' },
    GBPMXN: { provider: 'netdania_rtc' },
    GBPNOK: { provider: 'netdania_rtc' },
    GBPNZD: { provider: 'netdania_rtc' },
    GBPPLN: { provider: 'netdania_rtc' },
    GBPSEK: { provider: 'netdania_rtc' },
    GBPSGD: { provider: 'netdania_rtc' },
    GBPTRY: { provider: 'netdania_rtc' },
    GBPUSD: { provider: 'netdania_rtc' },
    GBPZAR: { provider: 'netdania_rtc' },
    KSMUSD: { provider: 'netdania_rtc' },
    LNKAUD: { provider: 'netdania_rtc' },
    LNKEUR: { provider: 'netdania_rtc' },
    LNKGBP: { provider: 'netdania_rtc' },
    LNKJPY: { provider: 'netdania_rtc' },
    LNKUSD: { provider: 'netdania_rtc' },
    LNKZAR: { provider: 'netdania_rtc' },
    LTCAUD: { provider: 'netdania_rtc' },
    LTCEUR: { provider: 'netdania_rtc' },
    LTCGBP: { provider: 'netdania_rtc' },
    LTCJPY: { provider: 'netdania_rtc' },
    LTCUSD: { provider: 'netdania_rtc' },
    LTCZAR: { provider: 'netdania_rtc' },
    MATUSD: { provider: 'netdania_rtc' },
    MXNJPY: { provider: 'netdania_rtc' },
    NOKSEK: { provider: 'netdania_rtc' },
    NZDCAD: { provider: 'netdania_rtc' },
    NZDCHF: { provider: 'netdania_rtc' },
    NZDJPY: { provider: 'netdania_rtc' },
    NZDSGD: { provider: 'netdania_rtc' },
    NZDUSD: { provider: 'netdania_rtc' },
    SOLUSD: { provider: 'netdania_rtc' },
    SOLZAR: { provider: 'netdania_rtc' },
    TRYJPY: { provider: 'netdania_rtc' },
    USDCAD: { provider: 'netdania_rtc' },
    USDCHF: { provider: 'netdania_rtc' },
    USDCNH: { provider: 'netdania_rtc' },
    USDCZK: { provider: 'netdania_rtc' },
    USDDKK: { provider: 'netdania_rtc' },
    USDHKD: { provider: 'netdania_rtc' },
    USDHUF: { provider: 'netdania_rtc' },
    USDILS: { provider: 'netdania_rtc' },
    USDJPY: { provider: 'netdania_rtc' },
    USDMXN: { provider: 'netdania_rtc' },
    USDNOK: { provider: 'netdania_rtc' },
    USDPLN: { provider: 'netdania_rtc' },
    USDSEK: { provider: 'netdania_rtc' },
    USDSGD: { provider: 'netdania_rtc' },
    USDTRY: { provider: 'netdania_rtc' },
    USDZAR: { provider: 'netdania_rtc' },
    XAGAUD: { provider: 'netdania_rtc' },
    XAGUSD: { provider: 'netdania_rtc' },
    XAUAUD: { provider: 'netdania_rtc' },
    XAUBTC: { provider: 'netdania_rtc' },
    XAUEUR: { provider: 'netdania_rtc' },
    XAUUSD: { provider: 'netdania_rtc' },
    XPDUSD: { provider: 'netdania_rtc' },
    XPTUSD: { provider: 'netdania_rtc' },
    RPLAUD: { provider: 'netdania_rtc' },
    RPLEUR: { provider: 'netdania_rtc' },
    RPLGBP: { provider: 'netdania_rtc' },
    RPLJPY: { provider: 'netdania_rtc' },
    RPLUSD: { provider: 'netdania_rtc' },
    RPLZAR: { provider: 'netdania_rtc' },
    ZARJPY: { provider: 'netdania_rtc' },
    AAVEUSD: { provider: 'cryptoxchange' },
    // ADAUSD: {provider: 'cryptoxchange'},
    ATMUSD: { provider: 'cryptoxchange' },
    ATMUSDT: { provider: 'cryptoxchange' },
    AVAXUSD: { provider: 'cryptoxchange' },
    BATUSD: { provider: 'cryptoxchange' },
    // BCHAUD: {provider: 'cryptoxchange'},
    BCHBTC: { provider: 'cryptoxchange' },
    // BCHEUR: {provider: 'cryptoxchange'},
    // BCHGBP: {provider: 'cryptoxchange'},
    // BCHUSD: {provider: 'cryptoxchange'},
    BCHUSDC: { provider: 'cryptoxchange' },
    BCHUSDT: { provider: 'cryptoxchange' },
    BNBUSD: { provider: 'cryptoxchange' },
    // BTCEUR: {provider: 'cryptoxchange'},
    // BTCGBP: {provider: 'cryptoxchange'},
    // BTCJPY: {provider: 'cryptoxchange'},
    // BTCSGD: {provider: 'cryptoxchange'},
    // BTCUSD: {provider: 'cryptoxchange'},
    BTCUSDC: { provider: 'cryptoxchange' },
    BTCUSDT: { provider: 'cryptoxchange' },
    // BTCZAR: {provider: 'cryptoxchange'},
    BUSDUSD: { provider: 'cryptoxchange' },
    COMPUSD: { provider: 'cryptoxchange' },
    CRVUSD: { provider: 'cryptoxchange' },
    DAIUSD: { provider: 'cryptoxchange' },
    DAIUSDT: { provider: 'cryptoxchange' },
    // DOTUSD: {provider: 'cryptoxchange'},
    ENJUSD: { provider: 'cryptoxchange' },
    // EOSBTC: {provider: 'cryptoxchange'},
    // EOSEUR: {provider: 'cryptoxchange'},
    // EOSUSD: {provider: 'cryptoxchange'},
    EOSUSDC: { provider: 'cryptoxchange' },
    EOSUSDT: { provider: 'cryptoxchange' },
    // ETHAUD: {provider: 'cryptoxchange'},
    ETHBTC: { provider: 'cryptoxchange' },
    // ETHEUR: {provider: 'cryptoxchange'},
    // ETHGBP: {provider: 'cryptoxchange'},
    // ETHUSD: {provider: 'cryptoxchange'},
    ETHUSDC: { provider: 'cryptoxchange' },
    ETHUSDT: { provider: 'cryptoxchange' },
    // ETHZAR: {provider: 'cryptoxchange'},
    GLMRUSD: { provider: 'cryptoxchange' },
    GLMUSDT: { provider: 'cryptoxchange' },
    // KSMUSD: {provider: 'cryptoxchange'},
    LRCUSD: { provider: 'cryptoxchange' },
    LRCUSDT: { provider: 'cryptoxchange' },
    // LTCAUD: {provider: 'cryptoxchange'},
    LTCBTC: { provider: 'cryptoxchange' },
    // LTCEUR: {provider: 'cryptoxchange'},
    // LTCGBP: {provider: 'cryptoxchange'},
    // LTCUSD: {provider: 'cryptoxchange'},
    LTCUSDC: { provider: 'cryptoxchange' },
    LTCUSDT: { provider: 'cryptoxchange' },
    MANAUSD: { provider: 'cryptoxchange' },
    MANUSDT: { provider: 'cryptoxchange' },
    MKRUSD: { provider: 'cryptoxchange' },
    MKRUSDT: { provider: 'cryptoxchange' },
    NEARUSD: { provider: 'cryptoxchange' },
    OMGUSD: { provider: 'cryptoxchange' },
    OMGUSDT: { provider: 'cryptoxchange' },
    SANDUSD: { provider: 'cryptoxchange' },
    // SOLUSD: {provider: 'cryptoxchange'},
    SUSHIUSD: { provider: 'cryptoxchange' },
    TRXUSD: { provider: 'cryptoxchange' },
    TRXUSDT: { provider: 'cryptoxchange' },
    UNIUSD: { provider: 'cryptoxchange' },
    UNIUSDT: { provider: 'cryptoxchange' },
    USDCAUD: { provider: 'cryptoxchange' },
    USDCCAD: { provider: 'cryptoxchange' },
    USDCCHF: { provider: 'cryptoxchange' },
    USDCEUR: { provider: 'cryptoxchange' },
    USDCGBP: { provider: 'cryptoxchange' },
    USDCUSD: { provider: 'cryptoxchange' },
    USDTAUD: { provider: 'cryptoxchange' },
    USDTCAD: { provider: 'cryptoxchange' },
    USDTCHF: { provider: 'cryptoxchange' },
    USDTEUR: { provider: 'cryptoxchange' },
    USDTGBP: { provider: 'cryptoxchange' },
    USDTJPY: { provider: 'cryptoxchange' },
    USDTUSD: { provider: 'cryptoxchange' },
    USDTUSDC: { provider: 'cryptoxchange' },
    USDTZAR: { provider: 'cryptoxchange' },
    XLMUSD: { provider: 'cryptoxchange' },
    XLMUSDT: { provider: 'cryptoxchange' },
    // XRPAUD: {provider: 'cryptoxchange'},
    // XRPEUR: {provider: 'cryptoxchange'},
    // XRPGBP: {provider: 'cryptoxchange'},
    // XRPJPY: {provider: 'cryptoxchange'},
    // XRPUSD: {provider: 'cryptoxchange'},
    XRPUSDC: { provider: 'cryptoxchange' },
    XRPUSDT: { provider: 'cryptoxchange' },
    XTZUSD: { provider: 'cryptoxchange' },
    XTZUSDT: { provider: 'cryptoxchange' }
};

export default netdaniaConfig;
