import { grpc } from '@improbable-eng/grpc-web';
import { createChannel, createClient, Metadata } from 'nice-grpc-web';
import { Middleware } from 'redux';
import { AppDispatch, RootState } from '../store';

import { MarginDownstreamEvent } from '@/compiled_proto/com/celertech/positionmanager/api/margin/DownstreamMarginProto';
import {
    PositionManagerServiceClient,
    PositionManagerServiceDefinition
} from '@/compiled_proto/com/celertech/positionmanager/api/pnl/PostionManagerServiceProto';
import { forEachAsync } from '@/helpers/asyncIterableHelper';
import { Logger } from '@/utils/logger';
import { v4 as uuidv4 } from 'uuid';
import { AccountUsage, setAccountUsage } from '../reducers/accountUsageSlice';
import { controlClearSubscriptions, controlInitSubscriptions, User } from '../reducers/authSlice';

let subscriptionId: any = null;
let accountUsagesSubscription: any = null;

const parseAccountUsageEvent = (accountUsageEvent: MarginDownstreamEvent): AccountUsage => ({
    account: accountUsageEvent.account,
    usage: accountUsageEvent.usage,
    accountValue: accountUsageEvent.accountValue,
    unrealisedPnl: accountUsageEvent.unrealisedPnl,
    currency: accountUsageEvent.currency
});

const setupAccountUsageSubscription = async (store: any, dispatch: AppDispatch, credentials: User) => {
    // Subscribe for changes to accountUsages
    accountUsagesSubscription = positionManagerClient.subscribeForAccountUsage(
        // subscribes to all accounts under the user, further optimization can be done to scope down subscribed events.
        { accountCode: credentials.accounts.map((account) => account.code) },
        {
            metadata: Metadata({
                'authorization-token': credentials.authToken
            })
        }
    );

    const currentSubscriptionId = uuidv4();
    subscriptionId = currentSubscriptionId;
    forEachAsync(
        accountUsagesSubscription,
        (accountUsageUpdate: { event: MarginDownstreamEvent; isHeartBeat: boolean }) => {
            if (accountUsageUpdate?.event && !accountUsageUpdate.isHeartBeat) {
                // Logger({
                //     title: 'Inbound: AccountUsageMiddleware event',
                //     callback: () => console.log(accountUsageUpdate.event)
                // });
                dispatch(setAccountUsage(parseAccountUsageEvent(accountUsageUpdate.event)));
            }
        },
        () => subscriptionId !== currentSubscriptionId,
        `AccountUsageMiddleware`
    ).catch((error) => console.error({ 'AccountUsageMiddleware subscription': error }));

    // Fetch latest accountUsage on init
    // await dispatch(changeBalanceAccount({ credentials }));
};

const wsChannelUrl = window.config.integration.celertech.websocket;
const wsChannel = createChannel(wsChannelUrl, grpc.WebsocketTransport());

// Subscribe to accountUsage updates

const positionManagerClient: PositionManagerServiceClient = createClient(PositionManagerServiceDefinition, wsChannel);

const AccountUsageMiddleware: Middleware = (store) => (next) => (action) => {
    if (controlClearSubscriptions.match(action)) {
        Logger({ title: `AccountUsageMiddleware: Clear Subscriptions`, callback: () => {} });
        subscriptionId = null;
        accountUsagesSubscription = null;
    } else if (controlInitSubscriptions.match(action)) {
        Logger({ title: `AccountUsageMiddleware: Initialise Subscriptions`, callback: () => {} });

        const dispatch: AppDispatch = store.dispatch;
        const state: RootState = store.getState();
        const creds = state.auth.user;
        // If not already listening, setup subscriptions
        if (!accountUsagesSubscription && creds) {
            setupAccountUsageSubscription(store, dispatch, creds);
        }
    }

    // Pass on to next middlewares in line
    next(action);
};

export default AccountUsageMiddleware;
